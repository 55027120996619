.root {
  position: fixed;
  bottom: 20px;
  left: 70px;
  right: 0;
  margin: 0 auto;
  max-width: 90%;
  min-height: 50px;
  max-height: 96vh;
  background: #f1f2f7;
  border: 1px solid #c1cbd1;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
}

.rootClosed {
  composes: root;
  position: relative;
  left: 0;
  bottom: 0;
  margin: 10px auto;
  width: 100%;
  max-width: calc(90% + 60px);
}

.root > div {
  max-height: 96vh;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: auto;
}

.root > div > div {
  margin-left: 0;
  max-width: 100%;
  max-height: 95vh;
  box-sizing: border-box;
  min-height: 96vh;
}

.root > div > div > img {
  display: none;
}

.header {
  position: relative;
  height: 100%;
  width: 100%;
  background: #fcd700;
  padding: 0 10px;
  cursor: pointer;
}

.header p {
  margin: 0;
  line-height: 50px;
  font-size: 15px;
  font-weight: bold;
  color: #001854;
  width: 80%;
}

.header img {
  position: absolute;
  height: 20px;
  right: 20px;
  top: 15px;
}

.openImage {
  height: 20px;
  position: absolute;
  right: 50px;
  top: 20px;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 1023px) {
  .root {
    /*display: none;*/
    width: 100%;
    max-width: 100vw;
    bottom: 0;
    left: 0;
    height: calc(100% - 55px);
    top: 55px;
    z-index: 2;
    overflow: hidden;
    box-sizing: border-box;
  }

  .root > div > div > div:first-child p {
    padding: 0;
  }

  .root > div > div > img {
    display: block;
  }

  .rootClosed {
    background: white;
    width: calc(90% + 35px);
    height: 40px;
    min-height: 0;
    top: 0;
  }

  .rootClosed > div {
    overflow: hidden;
  }

  .header {
    background: white;
  }

  .header p {
    line-height: 40px;
  }

  .header img {
    top: 10px;
  }

  .root > div > div {
    padding-top: 0;
    min-height: calc(100vh - 90px);
    overflow-x: hidden;
    max-height: none;
  }

  .openImage {
    position: relative;
    margin: 10px 0 0;
    float: right;
    clear: both;
    fill: red;
    top: 0;
    right: 5%;
  }
}

@media (max-width: 812px) {
  .rootClosed {
    width: calc(100% - 20px);
  }
}
