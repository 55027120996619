.menu-container {
  height: 100vh;
  background: #001b54;
  transition: width 0.2s;
  color: white;
  position: fixed;
  z-index: 3;
}

.user-display .closed .menu-open-icon {
  display: block;
}

.badge {
  width: 25px;
  height: 25px;
  color: #021640;
  background-color: #ffda02;
  border-radius: 50%;
  position: fixed;
  left: 52px;
  animation-delay: 0.3s;
  z-index: 1;
}

.number {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.user-display {
  position: fixed;
  bottom: 20px;
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  cursor: pointer;
  flex-direction: column;
  display: flex;
}

.user-display .open {
  padding: 10px 20px 0;
  text-align: left;
  width: 210px;
}

.user-display .smaller {
  font-size: 13px;
}

.user-display .smallest {
  font-size: 10px;
}

.user-display p {
  display: inline;
}

.closed {
  width: 70px;
  margin-right: 0;
}

.closed .menu-toggle {
  text-align: center;
  width: 40%;
}

.open {
  width: 250px;
  /* width:70px; */
}

.open .menu-open-icon {
  padding-right: 20px;
}

.menu-item {
  padding-left: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #4d618d;
  font-size: 16px;
  padding-top: 10px;
  outline: none;
}

.menu-item:hover {
  cursor: pointer;
  background-color: #102b64 !important;
}

.menu-item p {
  display: inline-block;
  font-weight: 300;
}

.menu-logo-closed {
  position: absolute;
  width: 100%;
  top: 0;
  max-width: 40px;
  padding: 15px;
}

.menu-open-icon {
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
}

.menu-toggle {
  display: none;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 21px;
  font-weight: normal;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-top-dark-closed {
  width: 100%;
  position: relative;
  padding-top: 65px;
}

.menu-item-closed p {
  margin: 0;
  font-weight: 300;
  font-size: 12px;
}

.menu-item-closed {
  text-align: center;
  /*padding-left: 15px;*/
  padding-bottom: 7px;
  border-bottom: 1px solid #4d618d;
  font-size: 16px;
  padding-top: 7px;
  position: relative;
  outline: none;
  background: #001b54;
}

.menu-item-closed:hover {
  cursor: pointer;
  background-color: #021640 !important;
  /*z-index: 0;*/
}

.menu-item-closed:hover .notificationBadgeCounter {
  left: 13em;
  position: absolute;
  transition: all 0.35s ease;
  transition-delay: 0.15s;
}

.menu-item-closed-img {
  padding-bottom: 2px;
  padding-top: 2px;
}

.font-awesome-icon {
  font-size: 21px;
  color: white;
  font-weight: lighter;
}

.submenu {
  position: absolute;
  top: 0;
  left: -190px;
  opacity: 1;
  background-color: #001b54;
  width: 220%;
  transition: ease 0.5s;
  border: none;
  z-index: -1;
}

.menu-item-closed:hover .submenu {
  display: block;
  opacity: 1;
  left: 70px;
  border-left: 1px solid #f1f2f7;
}

.nestedSubmenuItem {
  position: absolute;
  z-index: -1;
  margin-top: -6px;
  left: -300px;
  opacity: 0;
}

#notifications {
  z-index: -10;
}

.nestedSubmenu {
  z-index: 9999;
  width: 100%;
}

.submenu div:last-child:hover .nestedSubmenuItem {
  border-left: 1px solid white;
  opacity: 1;
  left: 168px;
  z-index: 9999;
  position: absolute;
  width: 100%;
  transition: all ease 0.5s;
}

.submenu .nestedSubmenu:last-child .menu-item-closed:last-child {
  border-bottom: none;
}

.submenu .menu-item-closed {
  float: left;
  width: 109%;
  border-bottom-color: #fff;
  padding-bottom: 6px;
}

.submenu-img,
.submenu-span {
  float: left;
  line-height: 46px;
  padding-left: 8px;
  text-transform: capitalize;
  -ms-text-size-adjust: 95%;
}

.submenu-img {
  padding: 10px 0 10px 10px;
}

@media (min-width: 1024px) {
  .menu-logo-closed {
    position: relative;
    padding-bottom: 20px;
  }
  .menu-top-dark-closed {
    padding-top: 10px;
  }
}

@media (max-width: 1023px) {
  .notifications {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 19px;
  }

  .notifications img,
  p {
    margin: 0;
  }

  .badge p {
    margin: 0;
    position: relative;
  }

  .notifications .badge {
    position: absolute;
    left: 95%;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  .menu-header {
    padding-left: 8px;
  }

  .menu-container {
    width: 100%;
    float: left;
  }

  .closed {
    max-height: 55px;
  }

  .closed .menu-toggle {
    text-align: left;
    width: 38px;
    left: 12px;
    top: 8px;
  }

  .user-display .closed {
    line-height: 55px;
    text-align: left;
    top: 0;
    right: 20px;
    width: auto;
    padding-right: 20px;
    position: absolute;
    display: none;
  }

  .user-display .closed .font-awesome-icon {
    margin: 0;
    padding-right: 20px;
    display: inline-block;
  }

  .menu-parent .closed {
    min-height: 55px;
    margin-right: 70px !important;
  }

  .user-display .open {
    height: auto;
    text-align: left;
  }

  .open {
    height: 100vh;
    z-index: 99;
    position: fixed;
  }

  .menu-item:last-child {
    /* position: absolute; */
    width: 100%;
    bottom: 0;
  }

  .menu-logo-closed {
    padding: 7px;
    left: 50px;
  }

  .menu-toggle {
    display: block;
  }

  .menu-top-dark-closed {
    padding-top: 55px;
  }

  .menu-item-closed {
    display: block;
    border: none;
  }

  .user-display {
    display: flex;
    bottom: -36px;
  }

  .menu-item-parent {
    transition: 0.4s;
    /* height: 278px; */
    max-height: 292px;
    width: 100%;
    overflow: hidden;
  }

  .menu-item-parent.closed {
    height: 0;
    max-height: 0;
  }

  .menu-item-parent > div {
    padding-left: 50px;
  }

  .menu-item-parent > div:last-child {
    position: relative;
    bottom: unset;
  }

  .right-chevron {
    display: inline-block;
    height: 22px;
    position: absolute;
    right: 22px;
    transition: 0.4s;
  }

  .spin {
    transform: rotate3d(1, 0, 0, 180deg);
  }
}

@media screen and (max-height: 558px) {
  .user-profile {
    position: relative !important;
  }
}
