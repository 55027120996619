.login-body {
  background-image: url('../assets/images/web-bg.png');
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.login-container {
  overflow: hidden;
  width: 760px;
  height: auto;
  text-wrap: normal;
  position: relative;
  top: 25%;
  margin: auto;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 60px;
  box-shadow: 0 10px 25px 10px #00000030;
  background: white;
  z-index: 2;
}

.text-input {
  outline: none;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid #0070b9;
  color: #001b54;
  height: 40px;
  margin: -30px auto 0;
  background: transparent;
  width: 100%;
  position: absolute;
  bottom: 0;
  box-shadow: none;
  padding: 0 15px;
}

.text-input:valid {
  margin: 0 auto;
}

.text-input:focus {
  margin: 0 auto;
}

.input-container {
  position: relative;
  width: 100%;
  min-height: 78px;
  /* margin-left:0px;
  margin-right:0px; */
  text-align: left;
  margin: auto;
  overflow: hidden;
}

.combo-parent {
  overflow: visible;
}

.combo-parent p {
  margin: 0 0 16px;
  color: red;
  font-size: 14px;
  height: auto;
}

.combo-parent > div {
  overflow: visible;
  margin-top: 31px;
}

.dropdown-list {
  border-color: #707071 !important;
  height: 47px;
  overflow: visible;
  outline: none;
  box-shadow: none !important;
}

.dropdown-list-error {
  border-color: red !important;
}

.negative-margin {
  margin-top: -23px;
}

.dropdown-list * {
  box-shadow: none;
  outline: none;
  color: #707071;
  font-weight: 400;
  font-size: 14px;
  padding-left: 6px;
}

.dropdown-list span {
  padding-left: 0;
}

.dropdown-list:before {
  content: 'Region *';
  color: #021640;
  position: absolute;
  bottom: 100%;
  width: 100%;
  font-size: 14px;
  height: 31px;
  line-height: 31px;
  z-index: 99;
}

.forgot-link {
  color: #021640;
  font-size: 14px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.login-box-logo {
  height: 80px;
}

.body-corner-logo-top {
  height: 150px;
  position: fixed;
  top: 10%;
  left: 10%;
}

.get-more-image-bottom {
  height: 50px;
  position: fixed;
  top: 90%;
  left: 80%;
}

.login-box-image-container {
  margin: 0 auto;
  width: 80px;
  text-align: center;
}

.login-validation-message {
  text-align: center;
  margin-top: 15px;
  font-size: 13px;
  color: red;
}

.login-validation-message-hidden {
  display: none;
}

.login-container input[readonly] {
  cursor: text;
  background-color: #fff;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

@media (max-width: 1023px) {
  .login-body {
    background-image: url('../assets/images/mobile-bg.png');
    background-size: 100% 100%;
    overflow: auto;
  }

  .login-container {
    width: 80%;
    position: relative;
    margin: auto;
    margin-bottom: 60px !important;
    left: 0;
    padding: 20px;
    top: 25%;
  }

  .body-corner-logo-top {
    top: 5%;
    left: 5%;
    height: 100px;
  }

  .get-more-image-bottom {
    height: 40px;
    position: fixed;
    top: 90%;
    left: calc(90% - 120px);
  }
}
