.tourNavContainer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  background: #f1f2f7;
  padding: 15px 20px;
}

.tourNavContainer div {
  margin: 0 auto;
  margin-right: 30px;
}

.tourNavContainer div:last-child {
  margin-right: 0;
}
.tourNavContainer div button {
  margin: 0;
}

.previous {
  background: #f1f2f7;
  color: #001b54;
}

.previous:hover {
  background: inherit;
}

.tourNavContainerBoundary {
  width: 100%;
  display: block;
  position: relative;
  z-index: 9999;
}

@media screen and (min-width: 1024px) {
  .tourNavContainerBoundary {
    height: 7em;
  }

  .tourNavContainer {
    width: 50%;
    margin: 0 auto;
    border-radius: 10px;
    box-sizing: border-box;
  }
  /*.tourNavContainer div {
    width: 33%;
    margin: 0 auto;
    border: 15px solid #f1f2f7;
    box-sizing: border-box;
    border-radius: 8px;
  } */
}
