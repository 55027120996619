.error {
  color: red;
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  animation: blinker 1.5s linear infinite;
}

.errorContainer {
  height: 100vh;
  position: relative;
}

@keyframes blinker {
  0% {
    opacity: 0.08;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}
