/* Grey block container (Note this was changed to blue) */
.grey-block-container:nth-child(4n-3) {
  padding-left: 0;
}

.grey-block-container:nth-child(4n) {
  padding-right: 0;
}

.grey-block {
  background: #1072b7;
  width: 100%;
  margin-top: 16px;
  margin-left: 1.5%;
  height: 150px;
  color: white;
  float: left;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 25px 10px #00000010;
  border-radius: 4px;
}

.negative-block {
  background: #1072b7;
  width: 100%;
  margin-top: 16px;
  margin-left: 0%;
  height: 135px;
  color: white;
  float: left;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 25px 10px #00000010;
  border-radius: 4px;
}

.grey-block-container {
  width: 25%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 8px;
  float: left;
}

.grey-block .block-title {
  text-align: left;
  text-transform: uppercase;
  padding: 10px 16px;
  margin: 0;
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: rgb(0, 27, 84);
  cursor: pointer;
}

.grey-block:first-child {
  margin-left: 0;
}

.block-title {
  text-align: left;
  text-transform: uppercase;
  padding: 10px 16px;
  margin: 0;
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: rgb(0, 27, 84);
  cursor: pointer;
}

.block-text-small {
  font-size: 15px;
  font-weight: bold;
  bottom: 0;
  top: 20px;
  width: 100%;
  cursor: pointer;
}

.block-text-small:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.block-text-small > * {
  display: inline-block;
  vertical-align: middle;
}

.block-text-small p {
  margin-top: 0;
  margin-bottom: 0;
}

.block-text-small > div {
  width: 100%;
  padding: 0px 16px;
  text-align: left;
  box-sizing: border-box;
}

.block-text-small > div > div:not(:last-child) {
  padding-bottom: 4px;
  border-bottom: solid 1px #fff8;
  margin-bottom: 4px;
}

.plain-text {
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
  color: #fff;
}

.negativeTradingText {
  font-size: 15px;
  font-weight: 300;
  color: #fff;
}

.negative-grey-block {
  width: 100%;
  padding: 0;
}

.negative-summary {
  line-height: 19px;
}

/* Dashboard-update setup */
.grey-block {
  width: 100%;
  float: none;
}

.grey-block-details-closed {
  display: none;
}

.grey-block-details-open {
  display: block;
  height: auto;
  width: 96%;
  margin: 0 auto;
}

@media (max-width: 553px) {
  .negative-summary {
    min-height: initial;
    height: initial;
  }
  .negative-summary .block-text-small {
    margin-bottom: 18px;
  }
}

@media (max-width: 1023px) {
  .negative-block {
    margin-top: 0;
  }

  .grey-block-container {
    width: 100%;
    padding: 10px 10px 0 10px !important;
    margin: 0;
    max-width: 100vw;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .grey-block {
    width: 100%;
    margin: 0;
    height: 150px;
    min-height: 100px;
    float: none;
    text-align: center;
    position: relative;
    overflow: hidden;
  }

  .grey-block-container:first-child {
    padding-left: 10px;
    margin-top: 0px;
  }

  .grey-block-container:nth-child(4n) {
    padding-right: 10px;
  }

  .grey-block-container:nth-child(4n-3) {
    padding-left: 10px;
  }

  .grey-block-container:focus {
    outline: none;
  }

  .block-title,
  .block-text {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .block-title.small {
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
