.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.body {
  position: relative;
  height: 80%;
  width: 80%;
  margin: 5% 10%;
  background: white;
  overflow: auto;
}

.oldForm {
  position: relative;
  height: 100%;
  width: 100%;
}

.oldForm * {
  max-width: 100%;
}

.oldForm > div {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  height: auto;
  padding-bottom: 15px;
}

.title {
  margin: 0 auto;
  width: 90%;
  padding-top: 20px;
}

.oldForm > div > div > p {
  display: none;
}

.oldForm input[type='submit'] {
  /*display: none;*/
}

.button {
  margin: 0 25%;
  height: 50px;
  border: none;
  outline: none;
  width: 50%;
  border-radius: 4px;
  background-color: #041d51;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}

.buttonContainer {
}
