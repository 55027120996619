.EntityController {
  margin-left: auto !important;
}

.DateRangePicker {
  margin: 0 !important;
}

button.DateRangePickerInput_calendarIcon.DateRangePickerInput_calendarIcon_1 {
  padding: 0;
  margin: 0;
}

.DateRangePickerInput,
.DateRangePickerInput,
.DateInput {
  background-color: #f1f2f7 !important;
}

.DateRangePicker_picker__directionLeft {
  left: 40px !important;
  top: 67px !important;
}

.DateRangePickerInput__withBorder {
  border: none !important;
}

.DateInput_fang {
  top: 57px !important;
  left: 50px;
}
