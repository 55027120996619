.tourContainer {
  position: absolute;
  bottom: 77px;
  left: 0;
  right: 0;
  z-index: 999;
  background: white;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: 0.45s;
}

.tourItemContainer {
  margin-top: 0;
  min-height: 100vh;
}

/*.tourItemContainer > span {*/
/*position: absolute;*/
/*bottom: 0;*/
/*left: 0;*/
/*right: 0;*/
/*}*/

.close {
  position: fixed;
  z-index: 9999;
  height: 32px;
  width: 33.7px;
  right: 0;
  margin-right: 25px;
  margin-top: 20px;
  background: #0070b9;
  border-radius: 20px;
  box-sizing: border-box;
}

.close svg {
  width: 34px;
  height: 31px;
}

.tourItem {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -30%);
  top: 30%;
  width: 100%;
  min-height: 100vh;
}

.tourItem > span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tourItem img {
  margin-top: 100px;
  width: 25%;
}

.heading {
  font-size: 2.2rem;
}

.heading {
  color: #0070b9;
  text-align: center;
  margin-bottom: 0;
}

p {
  color: inherit;
  font-size: 17px;
}

.welcome {
  /* padding: 0 10px;
  height: 100%;
  position: absolute; */
  padding: 0 10px;
  text-align: center;
}

.welcome + div {
  width: 100%;
}

.openTour {
  top: 0;
  bottom: 0;
  border-radius: 0;
  animation: slideUp 0.36s linear;
}

.closeTour {
  top: 100%;
}

@media screen and (min-width: 1024px) {
  .tourContainer {
    bottom: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .tourItem img {
    width: 50%;
  }
  .tourItemContainer span {
    position: relative;
  }
}

@keyframes slideUp {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}
