.root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 99vw;
  background: transparent;
  z-index: 9999;
  overflow: hidden;
}

.editRoot {
  composes: root;
  background: rgba(0, 0, 0, 0.35);
}

.body {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 16px;
  overflow: hidden;
}

.header {
  /*height: 50px;*/
  position: relative;
}

.header h2 {
  margin: 0;
}

.closeIcon {
  position: absolute;
  right: 2%;
  top: -3px;
  max-height: 25px;
  max-width: 25px;
  cursor: pointer;
}

.editButton {
  height: 30px;
  width: 80px;
  background: #021640;
  color: white;
  line-height: 30px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  border: none;
}

.dropdown {
  z-index: 999;
}

@media (max-width: 1024px) {
  .root p {
    margin: 20px 0;
  }
}

.confirmBoxBody {
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 400px;
  border-radius: 16px;
  text-align: center;
  padding: 16px;
}

.logo {
  max-height: 64px;
  max-width: 64px;
}

.title {
  font-size: 18px;
  color: #021640;
  font-weight: bold;
}

.infoText {
  font-size: 18px;
  color: #555;
  width: 100%;
  text-align: center;
}

.infoTextBorder {
  composes: infoText;
  margin-bottom: 0;
  padding-bottom: 16px;
}

.callText {
  composes: title;
  font-weight: normal;
}

.editInput {
  text-align: center;
  border: 1px solid #ccc;
  height: 36px;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.button {
  height: 48px;
  width: 132px;
  background: #021640;
  color: white;
  line-height: 48px;
  font-size: 14px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  box-shadow: none;
}

.yesNoButton {
  composes: button;
  margin: 0 7px;
}

.submitButton {
  composes: button;
  width: auto;
  min-width: 191px;
}

.spinner {
  margin-top: -10px;
}
