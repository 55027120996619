.toastify {
  min-height: 33px;
  width: 100%;
  margin-bottom: 3px;
  position: static;
  right: 0;
}

.toastifyBody {
  font-size: 11px;
}

.info {
  background: #0070b9;
}

.warning {
  background: #d64e4e;
}

.success {
  background: #247d4d;
}

@media only screen and (max-width: 1023px) {
  .toastify:first-child {
    top: 55px;
  }

  .toastify {
    top: 55px;
  }

  .toastOverride {
    z-index: 1 !important;
  }

  .Toastify__toast-container--top-right {
    top: 55px !important;
  }

  .Toastify div {
    top: 28px;
  }
}

@media only screen and (max-width: 480px) {
  .toastify {
    margin-bottom: 0;
  }

  .toastify:first-child {
    top: 55px;
  }

  .toastify {
    top: 55px;
  }

  .toastOverride {
    z-index: 1 !important;
  }

  .Toastify__toast-container--top-right {
    top: 55px !important;
  }

  .Toastify div {
    top: 28px;
  }
}
