.horizontal-scrolling {
  color: #001b54;
  text-align: center;
  margin-bottom: 13px;
}

@media screen and (min-width: 1023px) {
  .horizontal-scrolling {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .horizontal-scrolling {
    font-size: 15px;
  }
}
