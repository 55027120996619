body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: auto !important;
}

html {
  overflow: auto;
}

/* combo-boxes */
.combo-boxes {
  width: calc(90% + 60px);
  margin: 8px auto 0;
  clear: both;
  display: block;
  height: auto;
  min-height: 50px;
}

.reports .combo-boxes {
  min-height: 23px;
}

.Toastify__toast-container {
  z-index: 1 !important;
}

.reports.dates .combo-boxes {
  min-height: 98px;
}

.combo-stabilizer {
  float: left;
  margin: 0 !important;
}

.combo-boxes > div,
.combo-stabilizer > div {
  display: block;
  width: auto;
  margin: 0 0 0 15px;
  float: left;
}

.combo-stabilizer:first-child > div {
  margin: 0 10px 0 0;
}

.combo-header {
  color: #001b54;
  text-align: center;
  margin: 15px 0 0;
}

/* accountSelector */
.AccountSelector {
  margin: 0 auto;
  padding: 10px 20px 20px;
  background-color: #c4c4c4;
  width: 33%;
}

.AccountSelector h2.last-update {
  margin-top: 0;
}

/* button */
.button-container {
  position: relative;
  width: 100%;
  margin: 25px auto 5px;
}

.base-button {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  height: 47px;
  width: 100%;
  background-color: #001b54;
  color: #fff;
  border: none;
  border-radius: 7px;
  min-width: 102px;
  font-family: 'Lato', sans-serif;
}

.base-button-disabled {
  background-color: #c4c4c4;
}

.base-button:hover {
  cursor: pointer;
  background-color: #102b64;
}

.base-button-disabled:hover {
  background-color: #c4c4c4;
}

.is-cancel .base-button {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  color: #e02336;
  margin: 0;
  height: auto;
}

.is-cancel .base-button:hover,
.is-cancel .base-button:active {
  background-color: #fff;
}

.button-container.is-cancel {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.base-button:active {
  box-shadow: none;
  margin-top: 2px;
  background-color: #102b64;
  margin-bottom: -2px;
  box-shadow: inset 3px 3px 6px 3px rgba(0, 27, 84, 0.5);
}

.base-button.disabled {
  background-color: #666;
}

.menu-logo-open {
  position: relative;
  width: 100%;
  min-height: 50px;
  margin-bottom: -5px;
}

.blue-font {
  color: #0070b9;
}

.menu-toggle {
  display: none;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 21px;
  font-weight: normal;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-margin {
  height: 100vh;
}

/* dataViewer */
.closed-width {
  min-width: 90%;
  width: calc(100% - 70px);
}

.header-title {
  font-size: 16px;
  text-align: left;
  background: #001b54;
  color: white;
  height: 45px;
  font-weight: lighter;
}

.header-title > * {
  padding: 10px;
}

.text-button {
  color: #001b54;
  padding: 8px 15px;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  line-height: 30px;
  background: none;
  border: none;
  z-index: 0;
}

.last-update {
  text-align: left;
  font-size: 16px;
  color: #001b54;
  font-weight: 300;
  width: calc(90% + 60px);
  margin: 0 auto;
}

h2.last-update {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  color: #001b54;
  display: flex;
  flex-direction: column;
}

.faq h2.last-update {
  margin-bottom: 25px;
}

.label {
  font-size: 14px;
  color: #021640;
  margin-bottom: 5px;
  margin-top: 10px;
  text-align: left;
}

/* email */

.email-container {
  width: calc(90% + 60px);
  margin: 15px auto;
  text-align: right;
}

.email-sender {
  min-width: 102px;
  max-width: 70%;
  width: 500px;
  display: inline-flex;
  margin-right: 10px;
}

.email-sender input {
  border: solid 1px #95989a;
  border-radius: 8px;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  height: 47px;
  width: calc(100% - 112px);
  box-sizing: border-box;
  margin-bottom: 5px !important;
}

.email-sender > .button-container {
  width: auto;
  margin: 0 0 0 10px;
  display: inline-block;
  margin-bottom: 5px !important;
}

.mail-notice {
  font-size: 12px;
  position: absolute;
  text-align: left;
  bottom: -1.5em;
}

/* dashboard */
.dashboard-body {
  position: relative;
  height: 100%;
  transition: width 0s;
  overflow: hidden;
  background-color: #f1f2f7;
}

.dashboard-container {
  position: relative;
  min-height: 100vh;
  margin-left: 70px;
  width: calc(100vw - 70px);
  max-width: calc(100vw - 85px);
  overflow: auto;
}

.dashboard-container > * {
  position: relative;
}

/* pagination */
.pagination {
  width: 160px;
  display: block;
  margin: 0 auto;
}

.pagination button {
  width: 50px;
  margin: 15px;
  float: left;
}

.pagination button.base-button:active {
  margin-top: 15px;
  margin-bottom: 15px;
}

.table-cell {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
  margin: 10px 0 0 10px;
}

.corner-bottom-logo {
  position: relative;
  height: 40px;
  float: right;
  margin: 25px 25px 25px 0;
  bottom: 0;
}

.DatePicker > div:nth-child(2) {
  z-index: 25 !important;
}

.number-totals {
  font-size: 48px;
  color: #fcd700;
  line-height: 48px;
}

.small-totals {
  font-size: 36px;
  color: #fcd700;
  line-height: 30px;
  border-bottom: solid 1px #ccc;
  margin-top: 16px;
  width: 100%;
  display: block;
}

.small-totals span {
  color: #fff;
  font-size: 16px;
}

.DateInput_input {
  background-color: #0070b9;
  color: #fff;
}

.data-row > div:not(.separator) .blue-block .block-text {
  padding-top: 24px;
}

.yellow-text {
  color: #fcd700;
  font-size: 30px;
}

/*Graph stuff */
.graph-container {
  background-color: #fff;
  width: calc(90% + 60px);
  margin: 15px auto;
  padding: 15px;
  box-sizing: border-box;
}

g.tick text tspan {
  margin-left: -10px;
  display: contents;
}

g.tick text {
  color: #021640;
}

.add-user-textarea-input {
  border: 1px solid #021640;
  height: 51px;
  width: calc(100% - 35px);
  background-color: white;
  line-height: 17px;
  padding: 5px 15px;
  font-size: 14px;
  outline: none;
  color: #001b54;
  bottom: 0;
  box-shadow: none;
  resize: none;
}

svg.britechart.bar-chart {
  min-width: 100%;
  max-width: 100%;
}

.ReactTable .-loading.-active {
  z-index: 1 !important;
  position: fixed;
  padding: 0;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7 !important;
}

.ReactTable .-loading.-active > div {
  display: none;
}

.sk-circle > div {
  z-index: 2;
}

.sk-circle {
  position: absolute !important;
  left: 50%;
  left: calc(50% - 11px);
  z-index: 9999;
}

.base-button .sk-circle {
  position: relative !important;
  left: unset;
}

.search-icon {
  padding-top: 10px;
  width: 30px;
  height: 30px;
}

.arrow-icon {
  width: 30px;
  height: 30px;
  padding: 8px;
}

.horizontal-scrolling {
  color: #001b54;
  text-align: center;
}

.push-down-20 {
  margin-bottom: 20px;
}

.edit-multiselect > .rw-widget > .rw-widget-container {
  margin-top: 5px;
}

.rw-multiselect {
  text-align: left;
}

@media screen and (max-width: 425px) {
  .SingleDatePicker_picker__directionLeft {
    left: 10px !important;
  }

  .horizontal-scrolling {
    font-size: 15px;
  }

  .ReactTable {
    margin-right: -31px;
    margin-left: -31px;
  }
}

@media (max-width: 456px) {
  .ReactTable .rt-tbody,
  .ReactTable .rt-head {
    max-width: 100%;
  }

  .ReactTable .rt-tbody .rt-td {
    max-width: 33%;
  }

  .table-cell span {
    font-size: 16px;
    display: block;
    margin-right: 4px;
  }
}

@media (max-width: 1023px) {
  .excel-download-button {
    display: none !important;
  }

  .email-sender {
    max-width: 100%;
    width: 100%;
    display: block;
    margin-right: 20px;
  }

  .email-sender > div,
  .email-sender input {
    width: 100%;
    margin: 3px 0;
  }

  .mail-notice {
    position: inherit;
  }

  .email-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .menu-toggle {
    display: block;
  }

  .menu-item-closed {
    display: none;
  }

  .menu-item:last-child {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .menu-margin {
    display: none;
  }

  .menu-parent.closed {
    min-height: 55px;
    margin-right: 70px !important;
  }

  .mobile-only {
    display: block;
  }

  .mobile-search button {
    cursor: pointer;
    padding: 10px 15px;
    transition: 0.2s ease;
    display: block;
    float: none;
    width: auto;
    border-radius: 5px;
    clear: both;
    border: none;
    font-size: 16px;
    background-color: #fff;
  }

  .mobile-search button:hover {
    cursor: pointer;
    background-color: #0070b9;
    color: #fff;
  }

  .mobile-search-close {
    position: fixed !important;
    top: 10px;
    right: 10px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    box-sizing: border-box;
    background-color: #0070b9;
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    width: 41.25px;
    height: 41.25px;
    text-align: center;
    cursor: pointer;
    fill: #fff;
  }

  .mobile-search-buttons {
    position: fixed !important;
    bottom: 10px;
    right: 0;
    z-index: 1;
    width: auto;
  }

  .mobile-search-button {
    background-color: #fff;
    border-radius: 100%;
    width: 50px;
    text-align: center;
    padding: 0 !important;
    line-height: 40px;
    height: 50px;
    font-size: 22px;
    color: #0070b9;
    display: block;
    margin: 10px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }

  .combo-boxes > div {
    display: block;
    width: 100%;
    margin: 0;
  }

  .combo-boxes {
    display: block;
  }

  .combo-stabilizer > div {
    margin: 0;
    width: 100%;
  }

  .DateRangePicker {
    padding: 0 10px 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .DateInput_input,
  .DateInput__small,
  .DateRangePickerInput__withBorder {
    width: 100%;
  }

  .DateInput_input {
    width: 100%;
    min-height: 32px;
    box-sizing: border-box;
  }

  .DateRangePicker .DateInput__small_2 {
    padding: 10px 0 0;
  }

  .dashboard-container.faq > * {
    max-width: 100%;
  }

  .dashboard-container.faq > * {
    /* max-width: calc(90% + 60px); */
    margin-left: auto;
    margin-right: auto;
  }

  .faq .dashboard-body {
    width: 100%;
  }

  .SingleDatePicker_picker__directionLeft {
    left: 20% !important;
  }

  .dashboard-body {
    overflow-x: hidden;
    overflow-y: visible;
    height: auto;
    padding: 0 0 50px;
  }

  .dashboard-container {
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0;
    max-width: 100vw;
    width: 100vw;
  }

  .corner-bottom-logo {
    bottom: 0;
    height: 40px;
    max-width: 100%;
    width: auto;
    padding: 20px 28px;
  }

  .text-button {
    display: block;
    position: relative;
    margin: 0 auto;
  }

  h2.last-update {
    margin-top: 80px;
  }

  .email-sender > .button-container {
    float: left;
    margin: 0;
  }

  .base-button {
    display: block;
    clear: both;
    margin-top: 5px;
  }

  a.base-button,
  .email-fix a {
    float: left !important;
  }
}

@media screen and (min-width: 1024px) {
  .horizontal-scrolling {
    display: none;
  }

  .push-down-20 {
    display: flex;
    justify-content: flex-end;
  }

  .mobile-search,
  .mobile-search-close,
  .mobile-search-buttons,
  .mobile-only {
    display: none;
  }
}

@media (min-width: 1381px) {
  .combo-stabilizer {
    /*margin-right: 50% !important;*/
  }
}
