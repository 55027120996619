.body {
  position: relative;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
  border: 1px solid #c1cbd1;
  border-radius: 4px;
  transition: 0.3s;
  box-sizing: border-box;
}

.bodyClosed {
  composes: body;
  min-height: 50px;
  height: auto;
  overflow: hidden;
}

.bodyOpen {
  composes: body;
  min-height: 220px;
  height: auto;
  overflow: hidden;
  padding-bottom: 20px;
}

.header {
  position: relative;
  width: 100%;
  min-height: 50px;
  height: auto;
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
}

.date {
  position: absolute;
  right: 0;
  top: 0;
  background: #fcd102;
  line-height: 100%;
  height: 100%;
  max-height: 100%;
  width: 280px;
  text-align: right;
  padding-right: 25px;
  box-sizing: border-box;
  cursor: pointer;
}

.date::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.date > * {
  display: inline-block;
  vertical-align: middle;
}

.dateOpen {
  composes: date;
  background: transparent;
}

.dateClosed {
  composes: date;
  box-sizing: content-box;
  border-left-width: 25px;
  border-left-style: solid;
  border-image: linear-gradient(
      to bottom right,
      #f1f2f7 0%,
      #f1f2f7 50%,
      #fcd102 50%
    )
    1 100%;
}

.title {
  display: inline-block;
  height: auto;
  line-height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 15px;
}

.chevron {
  max-height: 15px;
  max-width: 15px;
  box-sizing: border-box;
  margin-left: 10px;
  margin-bottom: -2px;
  cursor: pointer;
}

.contentBody {
  position: relative;
  padding: 20px 10px;
  margin-top: 8px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.descriptionBlock {
  display: inline-block;
  max-width: 100%;
  top: 0;
  min-height: 100%;
  word-wrap: break-spaces;
  overflow: hidden;
  height: auto;
  position: relative;
}

.buttonContainer {
  height: 48px;
  overflow: visible;
  cursor: pointer;
  display: block;
  position: relative;
  line-height: 48px;
  margin-top: 16px;
  width: auto;
}

.button {
  background: #001a4a;
  position: relative;
  color: #fff;
  text-decoration: none;
  height: 48px;
  max-height: 48px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  box-sizing: border-box;
  line-height: 48px;
  border-radius: 4px;
  border-width: 1px;
}

.description {
  margin: 0;
  height: auto;
  overflow: hidden;
}

.image {
  display: inline-block;
  margin-right: 40px;
  max-width: 45%;
}

.deleteButton {
  composes: button;
  top: 10px;
  left: 10px;
}

.descriptionBlockHalf {
  composes: descriptionBlock;
  max-width: 50%;
  position: absolute;
  top: 20px;
}

.mobileTitle {
  display: none;
}

.mobileTitleClosed {
  composes: mobileTitle;
}

.mobileImage {
  display: none;
}

.editButton {
  composes: deleteButton;
  margin-left: 7px;
}

@media (max-width: 1023px) {
  .bodyClosed {
    height: auto;
  }

  .image {
    display: none;
  }

  .deleteButton {
    display: none;
  }

  .editButton {
    display: none;
  }

  .descriptionBlock {
    max-width: 100%;
    position: relative;
  }

  .descriptionBlockHalf {
    max-width: 100%;
  }

  .date {
    width: 100%;
    max-width: 100%;
    text-align: right;
    position: relative;
    height: 50px;
    padding-right: 15px;
    box-sizing: border-box;
    font-size: 12px;
    border: none;
  }

  .date > p {
    line-height: 100%;
    font-size: 12px;
  }

  .date > img {
    margin-bottom: 0;
  }

  .title {
    display: none;
  }

  .mobileImage {
    display: block;
    max-width: 95%;
    position: relative;
    margin: 0 auto;
  }

  .mobileTitle {
    composes: title;
    display: block;
    max-width: 100%;
    word-wrap: break-word;
    text-align: left;
    line-height: 15px;
    padding: 0 10px;
  }

  .mobileTitle:first-line {
    line-height: 50px;
  }

  .mobileTitleClosed {
    line-height: 30px;
  }

  .header {
    height: auto;
    padding-left: 0;
  }
}

@media (max-width: 600px) {
  .body {
    height: auto;
    padding-bottom: 30px;
    -webkit-tap-highlight-color: transparent;
  }

  .bodyClosed {
    height: auto;
    overflow: hidden;
    padding-bottom: 0;
  }

  .title {
    width: 100%;
  }

  .date p {
    margin-left: 0;
  }

  .dateClosed {
    width: 100%;
    border: none;
    padding-right: 10px;
  }

  .dateClosed p {
    margin-left: 25px;
  }

  .contentBody {
    position: relative;
    height: auto;
    margin-top: 0;
    padding-top: 0;
  }

  .descriptionBlock {
    height: auto;
    max-width: 100%;
    word-wrap: break-word;
    position: relative;
    display: block;
  }

  .descriptionBlockHalf {
    max-width: 100%;
  }

  .image {
    margin-right: 10px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
}
