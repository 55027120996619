.loginContainer {
  overflow: hidden;
  width: 450px;
  height: auto;
  max-width: 450px;
  text-wrap: normal;
  position: relative;
  top: 25%;
  margin: auto;
  margin-bottom: 60px !important;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 20px 40px;
  box-shadow: 0 10px 25px 10px #00000030;
  background: white;
  z-index: 2;
}

.links {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.register {
  text-align: center;
}

.link {
  font-size: 14px;
  cursor: pointer;
}

.link:first-child {
  margin-bottom: 25px;
}

.need-help {
  color: #021640;
  font-size: initial;
}

@media (max-width: 1023px) {
  .loginContainer {
    width: 80%;
    position: relative;
    margin: auto;
    left: 0;
    padding: 20px;
    top: 25%;
  }
}
