.passwordStrengthMeterLabel {
  font-size: 14px;
  display: block;
  text-align: right;
  position: relative;
  bottom: 11px;
}

.passwordStrengthMeter {
  text-align: left;
  height: 0;
}

.Strength {
  font-weight: normal;
  font-size: 14px;
}

.Weak {
  color: red;
  text-transform: uppercase;
}

.Strong {
  color: green;
  text-transform: uppercase;
}

.Fair {
  color: blue;
  text-transform: uppercase;
}

.Good {
  color: grey;
  text-transform: uppercase;
}
