.corner-bottom-logo {
  position: relative;
  float: right;
  bottom: 25px;
  right: 30px;
  height: 40px;
  max-width: 100%;
  width: auto;
  padding: 20px 28px;
  margin: 25px 25px 25px 0;
}

.underline {
  border-bottom: solid 1px #ccc8;
  border-top: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.date-text {
  font-size: 12px;
  font-weight: 300;
  color: #c4c4c4;
}

.dataRow {
  height: auto;
  width: calc(90% + 60px);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.dataRow:before {
  width: 100%;
  content: '';
  height: 100%;
  z-index: -10;
  position: absolute;
  transition: 1s linear;
  background-color: #f1f2f7;
  opacity: 0;
}

.dataRowLoading:before {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f2f7;
  z-index: 1;
  opacity: 0.7;
}

.dataRow.loading {
  position: relative;
}

.dataRow > div:not(.separator) .blue-block .block-text {
  padding-top: 24px;
}

.plain-text {
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
  color: #fff;
}

.plain-text {
  font-size: 12px;
  font-weight: 300;
  color: #c4c4c4;
}

.sub-header-title {
  text-transform: none;
  font-size: 15px;
}

.dashboard-error-display div:first-child {
  width: 100%;
  float: left;
}

.dashboard-error-display {
  width: calc(90% + 60px);
  margin: 0 auto;
}

.separator {
  width: 50%;
  float: left;
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 30px;
}

.separator .blue-block-container {
  width: 100%;
  line-height: 24px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

.separator .blue-block-container:first-child {
  margin-bottom: 16px;
}

.separator .blue-block-container:last-child .block-text {
  padding-bottom: 20px;
}

.separator .blue-block-container:first-child .blue-block {
  height: 90px;
}

.separator .blue-block {
  height: 133px;
}

.blue-block {
  background: #001b54;
  height: 240px;
  color: white;
  float: left;
  position: relative;
  box-shadow: 0 10px 25px 10px #00000010;
  text-align: left;
  padding: 0 15px;
  box-sizing: border-box;
  font-weight: bold !important;
  cursor: pointer;
}

.blue-block-container:first-child(4n-3) {
  padding-left: 0;
}

.blue-block-container:last-child {
  padding-right: 0;
}

.blue-block .block-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.blue-block .block-text {
  width: calc(100% - 30px);
}

.blue-block {
  width: 100% !important;
  border-radius: 4px;
}

.blue-block-container {
  width: 33%;
  line-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 8px 0 0;
  float: left;
}

.block-title {
  font-size: 13px;
  font-weight: 300;
}

.block-title {
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.block-text {
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  bottom: 0;
  top: 20px;
  width: 100%;
}

.block-text:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.block-text > * {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.block-text p {
  margin-top: 0;
  margin-bottom: 0;
}

.blue-block:first-child {
  margin-left: 0;
}

.blue-block {
  width: 32%;
}

.separator {
  width: 34%;
  float: left;
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 30px;
}

.separator .blue-block-container {
  width: 100%;
  line-height: 24px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

.separator .blue-block-container:first-child {
  margin-bottom: 16px;
}

.separator .blue-block-container:last-child .block-text {
  padding-bottom: 20px;
}

.separator .blue-block-container:first-child .blue-block {
  height: 90px;
}

.separator .blue-block {
  height: 133px;
}

.resetButton {
  height: 38px;
  box-shadow: none;
  background: #001a4a;
  color: white;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

@media (min-width: 480px) and (max-width: 812px) {
  .dataRow {
    margin: 0 !important;
    padding: 0 !important;
    width: inherit !important;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .dataRow {
    margin: 0 !important;
    padding: 0 !important;
    width: inherit !important;
  }
}

@media (max-width: 423px) {
  .blue-block-container {
    cursor: default;
  }
}

@media (max-width: 1023px) {
  .dashboard-error-display div:first-child {
    text-align: center;
  }

  .separator {
    width: 100%;
    clear: both;
    padding: 0;
  }

  .separator .blue-block-container:first-child,
  .separator .blue-block-container:last-child {
    margin-bottom: 0;
    padding: 0 10px;
  }

  .blue-block-container:last-child {
    padding-right: 10px;
  }

  .separator .blue-block {
    height: 110px;
  }

  .separator .blue-block-container:last-child .block-text {
    top: 40px;
    padding-bottom: 0;
  }

  .blue-block-container {
    padding: 0 10px;
    box-sizing: border-box;
    max-width: 100vw;
  }

  .block-title,
  .block-text {
    text-align: left;
  }

  .blue-block-container {
    width: 100%;
    padding: 0 10px 0 10px;
    cursor: pointer;
  }

  .blue-block .block-title,
  .blue-block .block-text {
    text-align: left;
  }

  .blue-block {
    width: 100%;
    margin-left: 0;
    height: 248px;
    min-height: 100px;
    float: none;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 15px;
  }

  .data-row {
    padding: 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }

  .corner-bottom-logo {
    bottom: 0;
    height: 40px;
    max-width: 100%;
    width: auto;
    padding: 20px 28px;
  }

  .resetButton {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .block-title.small {
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .blue-block-container {
    padding: 0 15px 0 0;
  }

  .separator {
    padding-right: 15px;
  }
}
