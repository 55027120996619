.title {
  text-align: center;
  margin: 10px;
  color: #021640;
}

.resendOtp {
  cursor: pointer;
}

.text {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #021640;
}

.heading {
  text-align: center;
  font-size: 20px;
  color: #021640;
}

.helpMessage {
  text-align: center;
}

.loginBodyUserDetails {
  background-image: url(/static/media/web-bg.b08f82e4.png);
  background-size: 100% 100%;
  width: 100vw;
  height: 152vh;
  position: relative;
  color: #021640;
}

.loginBody {
  background-image: url(/static/media/web-bg.b08f82e4.png);
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
  color: #021640;
}

.image {
  margin: 0 auto;
  width: 80px;
  text-align: center;
}

.logo {
  height: 80px;
}

.label {
  margin-left: 32%;
  color: #021640;
}

.registerContainer {
  overflow: hidden;
  max-width: 450px;
  height: auto;
  text-wrap: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: auto;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 60px;
  background: white;
  z-index: 2;
}

.registerContainer > p {
  color: #021640;
}

.userDetailsContainer {
  overflow: hidden;
  max-width: 450px;
  text-wrap: normal;
  position: relative;
  top: 10%;
  margin: auto;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 60px;
  background: white;
  z-index: 2;
  color: #021640;
}

@media (max-width: 1023px) {
  .registerContainer {
    width: 80%;
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
  }

  .userDetailsContainer {
    width: 80%;
    height: auto;
    position: relative;
    margin: auto;
    left: 0;
    top: 15%;
    padding: 20px;
  }
}

@media (max-width: 640px) {
  .image {
    display: none;
  }
}
