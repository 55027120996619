.container {
  width: calc(90% + 60px);
  margin: 0 auto 20px;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.4s;
  border: 1px solid #c1cbd1;
}

.containerClosed {
  composes: container;
  height: 50px;
}

.containerOpen {
  composes: container;
  overflow: hidden;
}

.header {
  position: relative;
  width: 100%;
  height: 50px;
  background: transparent;
  color: #041d51;
  cursor: pointer;
  padding: 15px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
}

.dataWrapper {
  text-align: left;
  padding: 15px;
}

.spokeBlock {
  text-align: left;
  width: calc(50% - 10px);
  display: inline-block;
  overflow: hidden;
  margin: 0;
  color: #041d51;
  padding: 0;
}

.worstBlock {
  composes: spokeBlock;
  margin-left: 20px;
}

.rankBlock {
  width: 100%;
  margin: 0;
  background: #3172b2;
  padding: 10px;
  color: white;
  border-radius: 4px;
  box-sizing: border-box;
}

.spokeText {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
}

.rankText {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.boldText {
  font-size: 15px;
  font-weight: bold;
  margin-right: 7px;
}

.rankItem {
  width: 99%;
  border-bottom: 1px solid #fff8;
  text-align: left;
  padding: 7px 0;
  cursor: pointer;
}

.rankItem:last-of-type {
  border: none;
}

.chevron {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 20px;
}

@media (max-width: 1023px) {
  .spokeBlock {
    display: block;
    width: 100%;
  }
  .worstBlock {
    margin-top: 20px;
    margin-left: 0;
  }

  .container {
    margin: 50px auto 0;
    /*margin-bottom: 50px;*/
    width: 94%;
  }
}

@media (max-width: 812px) {
  .container {
    margin: 15px auto 0;
    width: 96.5%;
  }
}
