.body {
  position: relative;
  min-height: 100vh;
  margin-left: 70px;
  min-width: calc(100vw - 70px);
  overflow: auto;
  box-sizing: border-box;
}

.heading {
  font-size: 24px;
  color: #021640;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
}

.heading > .productHeader {
  width: calc(90% + 60px);
  margin: 0 auto;
  padding-top: 20px;
}

.formContainer {
  position: relative;
  width: 50%;
  text-align: left;
  margin: auto;
  overflow: hidden;
  text-shadow: 0 0 black;
}

.textAreaContainer > div > textarea {
  background-color: white !important;
  border-radius: 3px;
  resize: none;
  height: 225px;
  border: 1px solid #707071;
}

.inputContainer {
  position: relative;
  width: 100%;
  min-height: 70px;
}

.inputContainer input[type='date'] {
  position: relative;
  height: 100%;
  min-height: 47px;
  width: 100%;
  border: 1px solid #707071;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 6px;
  font-size: 14px;
  color: #707071;
}

.label {
  font-size: 14px;
  color: #021640;
  margin-bottom: 5px;
  margin-top: 8px;
  text-align: left;
}

.textAreaDescr {
  border: 1px solid #707071;
  border-radius: 4px;
  height: 100px;
  width: 100%;
  background-color: white;
  line-height: 17px;
  padding: 5px 15px;
  font-size: 14px;
  outline: none;
  color: #001b54;
  bottom: 0;
  box-shadow: none;
  resize: none;
  box-sizing: border-box;
}

.productInput {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #707071;
  box-sizing: border-box;
  background: white;
  color: #707071;
  height: 47px;
  margin: 0 auto;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  padding: 6px;
}

.errorInput {
  composes: productInput;
  border-color: red;
}

.errorDescr {
  composes: textAreaDescr;
  border-color: red;
}

.errorText {
  composes: label;
  font-size: 14px;
  color: red;
  margin-top: 5px;
}

.submit {
  margin-top: 18px;
  height: 50px;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
  background-color: #041d51;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .body {
    padding-top: 50px;
    margin-left: 0;
  }

  .heading {
    text-align: center;
    font-weight: bold;
  }

  .productHeader {
    font-size: 24px;
  }

  .formContainer {
    min-width: 400px;
  }
}
