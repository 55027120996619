body {
  font-family: 'Lato', sans-serif;
  background: #f1f2f7;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

*:focus {
  outline: none;
}

a {
  cursor: pointer;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.Toastify {
  position: sticky;
  top: 0;
  z-index: 99;
}

.rt-th.header-title.rt-resizable-header.-sort-desc::before {
  content: '▼';
  float: right;
  margin-top: 9px;
  transform: scale(0.7);
}

.rt-th.header-title.rt-resizable-header.-sort-asc::before {
  content: '▲';
  float: right;
  margin-top: 5px;
  transform: scale(0.7);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.dashboard-container .closed-width {
  background: #f1f2f7;
}

.dashboard-container > div {
  width: 100%;
}

.outer-container {
  width: 100%;
  height: 100%;
  position: fixed !important;
  z-index: 1;
  top: 0;
  left: 0;
}

.modal-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #f1f2f799;
  z-index: 999;
}

a.base-button,
.email-fix a {
  line-height: 50px;
  width: auto;
  padding: 0 10px 0 15px;
  float: right;
  background-color: #247d4d;
  margin-bottom: 5px;
}

a.base-button:hover {
  background-color: #358e5e;
}

.fa-file-excel:before {
  content: '\f1c3';
}

span.fa.fa-file-excel {
  margin-left: 5px;
  position: relative;
  top: 0;
  left: -9px;
}

.entity-creator p {
  text-align: left;
}

.entity-creator input {
  border-bottom: none;
  width: 70%;
  margin-left: -30% !important;
}

.entity-creator input,
.entity-creator button {
  box-sizing: border-box;
  display: inline-block;
  background-color: transparent !important;
}

.entity-creator button {
  width: 30%;
  min-width: 100px;
  text-align: right;
}

.modal-body {
  position: absolute;
  height: auto;
  background: white;
  border-radius: 15px;
  border: 2px solid #0070b9;
  padding: 15px 30px;
  text-align: center;
  color: #021640;
  font-size: 18px;
  font-weight: 400;
  z-index: 1000;
}

.modal-close {
  position: absolute;
  width: 25px;
  height: 25px;
  line-height: 21px;
  top: 10px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
  color: #021640;
  font-size: 22px;
}

.header-title {
  background-color: #001b54 !important;
}

.-header .rt-th {
  border: none !important;
  border-right: none !important;
}

.-even > .rt-td {
  background-color: white !important;
}

.-odd > .rt-td {
  background-color: #e5e5ea !important;
}

.-header .rt-th {
  border-bottom: none !important;
}

.add-user-input-container {
  position: relative;
  min-height: 60px;
  text-align: left;
  margin: auto;
}

.add-user-text-input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #707071;
  box-sizing: border-box;
  color: #707071;
  height: 47px;
  margin: 0 auto;
  background: transparent;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  padding: 6px;
}

.add-user-input-container div {
  color: red;
  font-size: 14px;
}

.add-entity {
  float: right;
  font-size: 20px;
  z-index: 199;
  cursor: pointer;
  border-bottom: 2px solid #021640;
  padding: 0 10px 3px 4px;
}

#entityInput {
  width: 90%;
}

.table-cell.amount {
  text-align: left;
}

/* override the react table style so that it does not display infront of the modal */
.rt-noData {
  z-index: 0;
}

.reports .ReactTable {
  width: calc(90% + 60px);
  margin: 15px auto;
}

@media (max-width: 906px) {
  /* .edit-user */
  .table-cell {
    float: none;
  }
  .table-cell span {
    float: left;
  }
}

@media (max-width: 485px) {
  /* .edit-user */
  .table-cell {
    float: left;
  }

  .add-user-text-input {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .reports .ReactTable {
    max-width: calc(100% - 20px);
    box-sizing: border-box;
  }

  .Toastify {
    top: 55px;
  }

  .Toastify__toast-container {
    position: sticky !important;
    top: 55px !important;
  }

  .last-update {
    text-align: center;
    max-width: 100%;
    margin-left: 0;
    font-size: 14px;
    margin-top: 20px;
  }

  .email-fix a {
    float: left !important;
  }
}

@media (min-width: 1024px) {
  .Toastify {
    position: absolute;
    height: auto;
    width: 100%;
    top: 0;
  }

  .Toastify__toast-container--top-right {
    position: absolute !important;
    right: 0 !important;
    margin: 0;
    top: 0 !important;
  }
}
