.droplistContainer {
  /* z-index: 1; */
  position: relative;
}

.combo-stabilizer div .rw-widget-input:last-child {
  margin-right: 5px !important;
}

.combo-container .text-button {
  top: 6px;
  right: -100px;
  justify-content: center;
}

.rw-state-selected {
  background: transparent !important;
  border: none !important;
  color: #000 !important;
}

.rw-state-selected:hover {
  background: #e6e6e6 !important;
}

.combo-stabilizer {
  position: relative;
}

.combo-stabilizer:nth-child(3) {
  padding: 0 !important;
}

.combo-stabilizer .combo-container {
  margin: 0 0 10px;
  width: 300px;
}

.admin-entity-search .combo-container {
  width: 610px;
}

.rw-popup-container {
  z-index: 99999 !important;
}

.entity-selector {
  background: #fcd700 !important;
}

@media (min-width: 1281px) {
  .combo-container > .rw-dropdown-list {
    width: 300px !important;
  }

  /* Had to add this prefix so this does not affect other parts of the app */
  .entity-combobox .rw-widget-container {
    width: 300px !important;
  }

  .rw-popup-container .rw-popup-transition .rw-popup .rw-filter-input {
    width: 290px !important;
  }

  .admin-entity-search .combo-container > div {
    width: 610px !important;
  }

  .admin-entity-search .rw-popup-transition .rw-popup .rw-filter-input {
    width: 600px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .combo-boxes > div {
    width: inherit !important;
  }

  .combo-container {
    width: 300px !important;
  }

  .combo-stabilizer {
    padding-left: 0 !important;
  }

  /* Had to add this prefix so this does not affect other parts of the app */
  .entity-combobox .rw-widget-container {
    width: 300px !important;
  }

  .rw-filter-input {
    width: 290px !important;
  }

  .admin-entity-search .combo-container > div {
    width: 610px !important;
  }

  .admin-entity-search .rw-popup-transition .rw-popup .rw-filter-input {
    width: 600px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .combo-boxes > div {
    width: inherit;
  }

  .combo-container {
    float: left;
    width: 100% !important;
  }

  .combo-stabilizer {
    width: calc(100% - 22px) !important;
    display: flex;
    flex-direction: column;
    padding-left: 10px !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .combo-container {
    width: 100% !important;
  }

  .combo-container > div {
    width: 100% !important;
  }
}

@media (min-width: 1024px) and (max-width: 1145px) and (orientation: portrait) {
  .admin-entity-search .combo-container > div {
    width: 610px !important;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 480px) and (max-width: 812px) {
  .combo-boxes {
    display: flex !important;
    width: 100% !important;
    padding-bottom: 0 !important;
  }

  .combo-boxes > div {
    /* display: flex; */
    width: 100%;
    margin: 0px;
    /* flex-direction: column; */
    /* align-items: center; */
    padding: 0 10px;
  }

  .combo-stabilizer .combo-container {
    width: 100% !important;
  }

  .combo-stabilizer:first-child > div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .combo-container > div {
    width: 100% !important;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 479px) {
  .combo-boxes {
    display: flex !important;
    width: 100% !important;
    padding-bottom: 0 !important;
    margin-bottom: -1px;
  }

  .combo-boxes > div {
    display: flex;
    width: 100%;
    margin: 0px;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }

  .combo-stabilizer .combo-container {
    width: 100% !important;
  }

  .combo-stabilizer:first-child > div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .combo-container > div {
    width: 100% !important;
  }
}
