.customModal {
  background: #fff;
  max-width: 450px;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  padding: 0 !important;
}

.customOverlay {
  padding: 0 !important;
}

.closeButton {
  background: #0070b9;
  border-radius: 100%;
  padding: 5px;
  box-sizing: border-box;
  width: 41.25px;
  height: 41.25px;
  fill: #fff;
}

.heading {
  color: #021640;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  margin-bottom: 25px;
}

.inputContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0;
}

.input {
  padding: 1em;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  margin: 0 20px;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

.searchClear {
  position: absolute;
  left: 93.5%;
  transform: translate(-50%, -50%);
  top: 50%;
  padding-right: 20px;
}

.searchClearIcon {
  width: 30px;
  height: 30px;
}
.title {
  border-bottom: 2px solid #ccc;
  background: #fff;
  padding: 20px 20px 5px 25px;
  margin: 0;
  color: #0070b9;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.entity {
  color: #021640;
  border-bottom: 2px solid #ccc;
  box-sizing: border-box;
  padding: 15px 0 15px 35px;
  background: #fff;
}

.account {
  margin-right: 5px;
}

.entity:hover {
  background: #0070b9;
  transition: 0.3s all ease-in;
  color: #fff;
}

.entityDataContainer {
  background: red;
  margin-top: 5px;
  margin-bottom: 1em;
}

.noAccountMatch {
  color: #021640;
  border-top: 2px solid #ccc;
  padding: 10px 30px 0 29px;
  margin-top: 30px;
  font-size: 22px;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
}

.clearSearchText {
  font-family: 'Lato', sans-serif;
  text-align: center;
  color: red;
}

/*
  In case we're ever interested in having
  custom animation. Simply add these classnames
  to the Modal's classnames prop
*/
.transitionEnter {
  transform: scale(0);
}
.transitionEnterActive {
  transform: scale(1);
  transition: transform 100ms ease-in-out;
}
.transitionExit {
  transform: scale(1);
}
.transitionExitActive {
  transform: scale(0);
  transition: transform 100ms ease;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .searchClearIcon {
    width: 23px;
    height: 23px;
  }

  .input {
    font-size: 14px;
  }

  .searchClear {
    left: 90.5%;
    top: 55%;
  }

  .heading {
    font-size: 18px;
  }

  .closeButton {
    width: 40px;
    height: 40px;
  }

  .noAccountMatch {
    font-size: 17px;
  }
}
