.container {
  padding: 20px;
}

.title {
  font-size: 22px;
  text-align: center;
  font-style: bold;
  padding-bottom: 10px;
}

.text {
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  color: red;
  font-style: italic;
}

.code {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: red;
  font-style: bold;
}

.stack {
  padding: 20px;
}

.error > a {
  text-decoration: underline;
  margin-left: 2px;
}

.buttonContainer {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}
