.root {
  position: relative;
  width: 100%;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000090;
  z-index: 999;
}

.modalContainer * {
  color: #001b54;
}

.modalContainerMobile {
  composes: modalContainer;
  background: #fff;
}

.modalContainerMobile .modalBody {
  min-width: 312px;
}

.modalBody {
  position: absolute;
  height: 85%;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
  overflow-y: auto;
}

.modalBodyMobile {
  composes: modalBody;
  width: 80%;
}

.validBody {
  composes: modalBody;
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
}

.validBodyMobile {
  composes: modalBodyMobile;
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
}

/* .iconImage {
  padding-left: 7px;
} */

.iconMobileImage {
  padding-left: 0;
  padding-right: 20px;
}

/* .iconContainer {
  padding-left: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #4d618d;
  font-size: 16px;
  padding-top: 10px;
  outline: none;
  background-color: #001b54;
}

.iconContainer:hover {
  cursor: pointer;
  background-color: #102b64 !important;
} */

.iconText {
  display: inline-block;
  font-weight: 300;
  font-size: 12px;
  margin: 0;
}

.iconMobileText {
  composes: iconText;
  font-size: 16px;
  padding-left: 8px;
  vertical-align: top;
  padding-top: 5px;
}

.form {
  width: 80%;
  margin: 0 auto;
}

.submit {
  color: white;
}

.okay {
  composes: submit;
  width: 50%;
  min-width: 200px;
}

.cancel {
  border: 1px solid #001b54 !important;
  color: #001b54 !important;
  min-height: 47px;
}

.buttonContainer {
  margin-top: 12px;
  display: inline;
}

.buttonContainer > div {
  width: 49% !important;
  display: inline-block;
}

.buttonContainer > div:first-child {
  margin-right: 1%;
}

.buttonContainer > div:last-child {
  margin-left: 1%;
}

.helperText {
  text-align: center;
}

.helperTextMobile {
  composes: helperText;
}

.helperText > p {
  margin: 5px auto;
  max-width: 80%;
  word-wrap: break-spaces;
}

.helperTextMobile > p {
  max-width: 80%;
}

.boldText {
  margin-top: 15px !important;
  font-weight: bold;
}

.topLogo {
  height: 64px;
  width: 64px;
  margin: 25px auto 0;
}

.dropdown {
  border-color: #021640;
  min-height: 47px;
  margin-top: 7px !important;
}

.dropdownlistError {
  color: red;
  font-size: 14px;
}

/* Please call Me Configure */
.content {
  width: calc(90% + 60px);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.previewContent{
  width: inherit;
  display: flex;
  justify-content: center;
}
.preview {
  height: 350px;
  /* left: 200px; */
}
.configContent {
  width: 516px
}
.header {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #001B54;
}

.addOptionsText {
  position: relative;
  composes: header;
  color: #555555;
  margin-left: 10px;
}

.addOption {
  /* background-color: red; */
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

.inputContainer {
  display: flex;
  align-items: center
}

.closeIcon {
  color: #001B54;
  margin-top: 15px;
  margin-left: 15px;
}

.addIcon {
  color: #001B54
}

.inputContainer > div:first-child {
  width: 425px;
  margin: 0;
  min-height: 35px;
}

.inputContainer > div:first-child > input {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  color: #001B54
}

.fieldContent {
  margin-top: 12px;
}

.configSubmit {
  composes: submit;
  width: 425px;
  height: 35px;
  background: #001B54;
}

.configSubmit:hover {
  background: #001B54;
}

.configComplete {
  color: #0d7100;
  margin-top: 15px;
}

.label {
  font-size: 14px;
  color: #021640;
  margin-bottom: 5px;
  margin-top: 8px;
  text-align: left;
}

.pleaseCallInput {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #707071;
  box-sizing: border-box;
  background: #fff;
  color: #707071;
  height: 47px;
  margin: 0 auto;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  padding: 6px;
}