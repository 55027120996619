.title {
  text-align: center;
  margin: 10px;
  color: #021640;
  cursor: pointer;
}

.text {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #021640;
}

.heading {
  text-align: center;
  font-size: 20px;
  color: #021640;
}

.loginBody {
  background-image: url(/static/media/web-bg.b08f82e4.png);
  position: relative;
  color: #021640;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.status {
  text-align: center;
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.image {
  margin: 0 auto;
  width: 80px;
  text-align: center;
}

.logo {
  height: 80px;
}

.spinner {
  position: relative !important;
  margin: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
}

.goBack {
  color: #021640;
  font-size: 14px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
}

.spinnerContainer {
  margin: 0;
  position: relative;
}

.otpResend {
  text-align: center;
  color: green;
}

.label {
  margin-left: 32%;
  color: #021640;
}

.registerContainer {
  overflow: hidden;
  max-width: 450px;
  height: auto;
  text-wrap: normal;
  position: relative;
  top: 10%;
  margin: auto;
  margin-bottom: 60px !important;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 20px 40px;
  background: white;
  z-index: 2;
}

.registerContainer > p {
  color: #021640;
}

.OTPInfo {
  font-size: 12px;
}

@media (max-width: 1023px) {
  .registerContainer {
    width: 80%;
    position: relative;
    margin: auto;
    left: 0;
    padding: 20px;
  }

  .userDetailsContainer {
    width: 80%;
    height: auto;
    position: relative;
    margin: auto;
    left: 0;
    top: 15%;
    padding: 20px;
  }
}
