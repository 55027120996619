.modal-body {
  margin: 5% 15vw;
  width: 70vw;
}

.modal-body.drilldown {
  background-color: #fff;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.drilldown .data-viewer .rw-dropdown-list {
  width: 300px;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  z-index: 1;
}

.data-viewer .rt-th.rt-resizable-header.-cursor-pointer {
  background: #001b54;
  color: white;
}

.data-viewer .ReactTable .rt-thead .rt-th:not(:last-child),
.ReactTable .rt-thead .rt-td:not(:last-child),
.data-viewer .ReactTable .rt-tbody .rt-td:not(:last-child) {
  border-right: 1px solid rgb(231, 233, 235);
}

.data-viewer .ReactTable.-striped .rt-tr.-odd {
  background: #ced3de;
}

.data-viewer .table-cell {
  margin: 0;
  text-align: left;
}

.data-viewer h4,
.data-viewer .last-update {
  margin-bottom: 10px;
  text-align: center;
}

.data-viewer.sk-spinner {
  margin: 100px auto !important;
}

.data-viewer select {
  font-size: 16px;
  color: #001b54;
  height: 30px;
  padding: 5px 12px 5px 30px;
  width: 207px;
  margin-bottom: 10px;
  border-radius: 0;
}

.data-viewer select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.data-viewer select {
  background-image: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) 50%, calc(100% - 15px) 50%, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.data-viewer select:focus {
  background-image: linear-gradient(45deg, black 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, black 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 15px) 50%, calc(100% - 20px) 50%, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

@media (max-width: 1023px) {
  .modal-body {
    margin: 60px 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 90vh !important ;
  }
}
