.tourItemContainer {
  border-bottom: 1px solid #0070b9;
  box-sizing: border-box;
  padding-bottom: 15px;
  margin: 5px 20px 15px;
}

.tourItemContainer:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.imageContainer {
  width: 49%;
}

.tourItemContainer img {
  width: 100%;
  margin-left: 0 !important;
}

.heading {
  color: #0070b9;
  font-size: 16px;
  text-transform: uppercase;
}
.heading h2 {
  margin-bottom: 0;
}

.caption {
  margin-bottom: 10px;
  color: #001b54;
}

.caption h4 {
  margin-top: 15px;
}

.explanations {
  color: #001b54;
  margin-top: 20px;
  white-space: pre-wrap;
}

@media screen and (max-width: 1023px) {
  .imageContainer {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .tourItemContainer {
    border-bottom: none;
  }
  .content {
    display: flex;
  }
  .content img {
    width: 100%;
    max-height: 320px;
    height: auto;
    display: flex;
    margin-left: -12px;
  }

  .explanations {
    width: 50%;
    padding-left: 1.6em;
    display: flex;
    flex-direction: column;
  }
  .explanations span {
    display: block;
    margin-bottom: 2em;
  }
  .explanations span:last-child {
    margin-bottom: 0;
  }
}
