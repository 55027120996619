.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.content {
  padding-top: -40px;
}
