.body {
  position: relative;
  min-height: 100vh;
  margin-left: 70px;
  width: calc(100vw - 70px);
  overflow: auto;
  padding-bottom: 70px;
  box-sizing: border-box;
}

.heading {
  font-size: 24px;
  color: #021640;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
}

.heading > .productHeader {
  width: calc(90% + 60px);
  margin: 0 auto;
  padding-top: 20px;
  font-size: 24px;
  text-transform: uppercase;
}

.noContent {
  composes: heading;
  margin: auto;
  text-align: center;
  font-size: 14px;
}

.cornerLogo {
  position: absolute;
  height: 40px;
  bottom: 25px;
  right: 30px;
}

@media screen and (max-width: 1023px) {
  .body {
    padding-top: 50px;
    margin-left: 0;
    width: 100vw;
  }

  .heading {
    text-align: center;
  }
}
