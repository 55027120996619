.root {
  width: calc(90% + 60px);
  margin: 0 auto 20px;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.4s;
  border: 1px solid #c1cbd1;
}

.rootClosed {
  composes: root;
  height: 50px;
}

.header {
  position: relative;
  width: 100%;
  height: 50px;
  background: transparent;
  color: #041d51;
  cursor: pointer;
  padding: 15px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px 15px;
  box-sizing: border-box;
}

.subHeader {
  margin-top: 0;
  color: #001b54;
}

.dataBlock {
  position: relative;
  width: 100%;
  background: #3172b2;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
}

.dataRow {
  position: relative;
  color: white;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.dataRow:last-child {
  border-bottom: none;
}

.innerRow {
  position: relative;
  width: 100%;
}

.innerRow > p {
  position: relative;
  display: inline-block;
  margin: 14px 0 5px;
}

.innerRow > p:first-child {
  width: 40%;
}

.amount {
  text-align: right;
  padding-right: 50px;
  box-sizing: border-box;
  width: 60%;
}

.chevron {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 20px;
}

.chevronYellow {
  composes: chevron;
}

.separator {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 3px;
  background: #36bd74;
}

.salesCount {
  font-size: 11px;
  margin: 0 7px 0 0;
}

@media (max-width: 1024px) {
  .root {
    margin: 15px auto 0;
    width: 94.3%;
  }
}

@media (max-width: 812px) {
  .root {
    width: 96%;
  }
}
