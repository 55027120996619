body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: auto !important;
}

html {
  overflow: auto;
}

/* combo-boxes */
.combo-boxes {
  width: calc(90% + 60px);
  margin: 8px auto 0;
  clear: both;
  display: block;
  height: auto;
  min-height: 50px;
}

.reports .combo-boxes {
  min-height: 23px;
}

.Toastify__toast-container {
  z-index: 1 !important;
}

.reports.dates .combo-boxes {
  min-height: 98px;
}

.combo-stabilizer {
  float: left;
  margin: 0 !important;
}

.combo-boxes > div,
.combo-stabilizer > div {
  display: block;
  width: auto;
  margin: 0 0 0 15px;
  float: left;
}

.combo-stabilizer:first-child > div {
  margin: 0 10px 0 0;
}

.combo-header {
  color: #001b54;
  text-align: center;
  margin: 15px 0 0;
}

/* accountSelector */
.AccountSelector {
  margin: 0 auto;
  padding: 10px 20px 20px;
  background-color: #c4c4c4;
  width: 33%;
}

.AccountSelector h2.last-update {
  margin-top: 0;
}

/* button */
.button-container {
  position: relative;
  width: 100%;
  margin: 25px auto 5px;
}

.base-button {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  height: 47px;
  width: 100%;
  background-color: #001b54;
  color: #fff;
  border: none;
  border-radius: 7px;
  min-width: 102px;
  font-family: 'Lato', sans-serif;
}

.base-button-disabled {
  background-color: #c4c4c4;
}

.base-button:hover {
  cursor: pointer;
  background-color: #102b64;
}

.base-button-disabled:hover {
  background-color: #c4c4c4;
}

.is-cancel .base-button {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  color: #e02336;
  margin: 0;
  height: auto;
}

.is-cancel .base-button:hover,
.is-cancel .base-button:active {
  background-color: #fff;
}

.button-container.is-cancel {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.base-button:active {
  box-shadow: none;
  margin-top: 2px;
  background-color: #102b64;
  margin-bottom: -2px;
  box-shadow: inset 3px 3px 6px 3px rgba(0, 27, 84, 0.5);
}

.base-button.disabled {
  background-color: #666;
}

.menu-logo-open {
  position: relative;
  width: 100%;
  min-height: 50px;
  margin-bottom: -5px;
}

.blue-font {
  color: #0070b9;
}

.menu-toggle {
  display: none;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 21px;
  font-weight: normal;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-margin {
  height: 100vh;
}

/* dataViewer */
.closed-width {
  min-width: 90%;
  width: calc(100% - 70px);
}

.header-title {
  font-size: 16px;
  text-align: left;
  background: #001b54;
  color: white;
  height: 45px;
  font-weight: lighter;
}

.header-title > * {
  padding: 10px;
}

.text-button {
  color: #001b54;
  padding: 8px 15px;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  line-height: 30px;
  background: none;
  border: none;
  z-index: 0;
}

.last-update {
  text-align: left;
  font-size: 16px;
  color: #001b54;
  font-weight: 300;
  width: calc(90% + 60px);
  margin: 0 auto;
}

h2.last-update {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  color: #001b54;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.faq h2.last-update {
  margin-bottom: 25px;
}

.label {
  font-size: 14px;
  color: #021640;
  margin-bottom: 5px;
  margin-top: 10px;
  text-align: left;
}

/* email */

.email-container {
  width: calc(90% + 60px);
  margin: 15px auto;
  text-align: right;
}

.email-sender {
  min-width: 102px;
  max-width: 70%;
  width: 500px;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 10px;
}

.email-sender input {
  border: solid 1px #95989a;
  border-radius: 8px;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  height: 47px;
  width: calc(100% - 112px);
  box-sizing: border-box;
  margin-bottom: 5px !important;
}

.email-sender > .button-container {
  width: auto;
  margin: 0 0 0 10px;
  display: inline-block;
  margin-bottom: 5px !important;
}

.mail-notice {
  font-size: 12px;
  position: absolute;
  text-align: left;
  bottom: -1.5em;
}

/* dashboard */
.dashboard-body {
  position: relative;
  height: 100%;
  transition: width 0s;
  overflow: hidden;
  background-color: #f1f2f7;
}

.dashboard-container {
  position: relative;
  min-height: 100vh;
  margin-left: 70px;
  width: calc(100vw - 70px);
  max-width: calc(100vw - 85px);
  overflow: auto;
}

.dashboard-container > * {
  position: relative;
}

/* pagination */
.pagination {
  width: 160px;
  display: block;
  margin: 0 auto;
}

.pagination button {
  width: 50px;
  margin: 15px;
  float: left;
}

.pagination button.base-button:active {
  margin-top: 15px;
  margin-bottom: 15px;
}

.table-cell {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
  margin: 10px 0 0 10px;
}

.corner-bottom-logo {
  position: relative;
  height: 40px;
  float: right;
  margin: 25px 25px 25px 0;
  bottom: 0;
}

.DatePicker > div:nth-child(2) {
  z-index: 25 !important;
}

.number-totals {
  font-size: 48px;
  color: #fcd700;
  line-height: 48px;
}

.small-totals {
  font-size: 36px;
  color: #fcd700;
  line-height: 30px;
  border-bottom: solid 1px #ccc;
  margin-top: 16px;
  width: 100%;
  display: block;
}

.small-totals span {
  color: #fff;
  font-size: 16px;
}

.DateInput_input {
  background-color: #0070b9;
  color: #fff;
}

.data-row > div:not(.separator) .blue-block .block-text {
  padding-top: 24px;
}

.yellow-text {
  color: #fcd700;
  font-size: 30px;
}

/*Graph stuff */
.graph-container {
  background-color: #fff;
  width: calc(90% + 60px);
  margin: 15px auto;
  padding: 15px;
  box-sizing: border-box;
}

g.tick text tspan {
  margin-left: -10px;
  display: contents;
}

g.tick text {
  color: #021640;
}

.add-user-textarea-input {
  border: 1px solid #021640;
  height: 51px;
  width: calc(100% - 35px);
  background-color: white;
  line-height: 17px;
  padding: 5px 15px;
  font-size: 14px;
  outline: none;
  color: #001b54;
  bottom: 0;
  box-shadow: none;
  resize: none;
}

svg.britechart.bar-chart {
  min-width: 100%;
  max-width: 100%;
}

.ReactTable .-loading.-active {
  z-index: 1 !important;
  position: fixed;
  padding: 0;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7 !important;
}

.ReactTable .-loading.-active > div {
  display: none;
}

.sk-circle > div {
  z-index: 2;
}

.sk-circle {
  position: absolute !important;
  left: 50%;
  left: calc(50% - 11px);
  z-index: 9999;
}

.base-button .sk-circle {
  position: relative !important;
  left: unset;
}

.search-icon {
  padding-top: 10px;
  width: 30px;
  height: 30px;
}

.arrow-icon {
  width: 30px;
  height: 30px;
  padding: 8px;
}

.horizontal-scrolling {
  color: #001b54;
  text-align: center;
}

.push-down-20 {
  margin-bottom: 20px;
}

.edit-multiselect > .rw-widget > .rw-widget-container {
  margin-top: 5px;
}

.rw-multiselect {
  text-align: left;
}

@media screen and (max-width: 425px) {
  .SingleDatePicker_picker__directionLeft {
    left: 10px !important;
  }

  .horizontal-scrolling {
    font-size: 15px;
  }

  .ReactTable {
    margin-right: -31px;
    margin-left: -31px;
  }
}

@media (max-width: 456px) {
  .ReactTable .rt-tbody,
  .ReactTable .rt-head {
    max-width: 100%;
  }

  .ReactTable .rt-tbody .rt-td {
    max-width: 33%;
  }

  .table-cell span {
    font-size: 16px;
    display: block;
    margin-right: 4px;
  }
}

@media (max-width: 1023px) {
  .excel-download-button {
    display: none !important;
  }

  .email-sender {
    max-width: 100%;
    width: 100%;
    display: block;
    margin-right: 20px;
  }

  .email-sender > div,
  .email-sender input {
    width: 100%;
    margin: 3px 0;
  }

  .mail-notice {
    position: inherit;
  }

  .email-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .menu-toggle {
    display: block;
  }

  .menu-item-closed {
    display: none;
  }

  .menu-item:last-child {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .menu-margin {
    display: none;
  }

  .menu-parent.closed {
    min-height: 55px;
    margin-right: 70px !important;
  }

  .mobile-only {
    display: block;
  }

  .mobile-search button {
    cursor: pointer;
    padding: 10px 15px;
    transition: 0.2s ease;
    display: block;
    float: none;
    width: auto;
    border-radius: 5px;
    clear: both;
    border: none;
    font-size: 16px;
    background-color: #fff;
  }

  .mobile-search button:hover {
    cursor: pointer;
    background-color: #0070b9;
    color: #fff;
  }

  .mobile-search-close {
    position: fixed !important;
    top: 10px;
    right: 10px;
    border-radius: 100%;
    box-sizing: border-box;
    background-color: #0070b9;
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    width: 41.25px;
    height: 41.25px;
    text-align: center;
    cursor: pointer;
    fill: #fff;
  }

  .mobile-search-buttons {
    position: fixed !important;
    bottom: 10px;
    right: 0;
    z-index: 1;
    width: auto;
  }

  .mobile-search-button {
    background-color: #fff;
    border-radius: 100%;
    width: 50px;
    text-align: center;
    padding: 0 !important;
    line-height: 40px;
    height: 50px;
    font-size: 22px;
    color: #0070b9;
    display: block;
    margin: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }

  .combo-boxes > div {
    display: block;
    width: 100%;
    margin: 0;
  }

  .combo-boxes {
    display: block;
  }

  .combo-stabilizer > div {
    margin: 0;
    width: 100%;
  }

  .DateRangePicker {
    padding: 0 10px 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .DateInput_input,
  .DateInput__small,
  .DateRangePickerInput__withBorder {
    width: 100%;
  }

  .DateInput_input {
    width: 100%;
    min-height: 32px;
    box-sizing: border-box;
  }

  .DateRangePicker .DateInput__small_2 {
    padding: 10px 0 0;
  }

  .dashboard-container.faq > * {
    max-width: 100%;
  }

  .dashboard-container.faq > * {
    /* max-width: calc(90% + 60px); */
    margin-left: auto;
    margin-right: auto;
  }

  .faq .dashboard-body {
    width: 100%;
  }

  .SingleDatePicker_picker__directionLeft {
    left: 20% !important;
  }

  .dashboard-body {
    overflow-x: hidden;
    overflow-y: visible;
    height: auto;
    padding: 0 0 50px;
  }

  .dashboard-container {
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0;
    max-width: 100vw;
    width: 100vw;
  }

  .corner-bottom-logo {
    bottom: 0;
    height: 40px;
    max-width: 100%;
    width: auto;
    padding: 20px 28px;
  }

  .text-button {
    display: block;
    position: relative;
    margin: 0 auto;
  }

  h2.last-update {
    margin-top: 80px;
  }

  .email-sender > .button-container {
    float: left;
    margin: 0;
  }

  .base-button {
    display: block;
    clear: both;
    margin-top: 5px;
  }

  a.base-button,
  .email-fix a {
    float: left !important;
  }
}

@media screen and (min-width: 1024px) {
  .horizontal-scrolling {
    display: none;
  }

  .push-down-20 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .mobile-search,
  .mobile-search-close,
  .mobile-search-buttons,
  .mobile-only {
    display: none;
  }
}

@media (min-width: 1381px) {
  .combo-stabilizer {
    /*margin-right: 50% !important;*/
  }
}

.droplistContainer {
  /* z-index: 1; */
  position: relative;
}

.combo-stabilizer div .rw-widget-input:last-child {
  margin-right: 5px !important;
}

.combo-container .text-button {
  top: 6px;
  right: -100px;
  -webkit-justify-content: center;
          justify-content: center;
}

.rw-state-selected {
  background: transparent !important;
  border: none !important;
  color: #000 !important;
}

.rw-state-selected:hover {
  background: #e6e6e6 !important;
}

.combo-stabilizer {
  position: relative;
}

.combo-stabilizer:nth-child(3) {
  padding: 0 !important;
}

.combo-stabilizer .combo-container {
  margin: 0 0 10px;
  width: 300px;
}

.admin-entity-search .combo-container {
  width: 610px;
}

.rw-popup-container {
  z-index: 99999 !important;
}

.entity-selector {
  background: #fcd700 !important;
}

@media (min-width: 1281px) {
  .combo-container > .rw-dropdown-list {
    width: 300px !important;
  }

  /* Had to add this prefix so this does not affect other parts of the app */
  .entity-combobox .rw-widget-container {
    width: 300px !important;
  }

  .rw-popup-container .rw-popup-transition .rw-popup .rw-filter-input {
    width: 290px !important;
  }

  .admin-entity-search .combo-container > div {
    width: 610px !important;
  }

  .admin-entity-search .rw-popup-transition .rw-popup .rw-filter-input {
    width: 600px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .combo-boxes > div {
    width: inherit !important;
  }

  .combo-container {
    width: 300px !important;
  }

  .combo-stabilizer {
    padding-left: 0 !important;
  }

  /* Had to add this prefix so this does not affect other parts of the app */
  .entity-combobox .rw-widget-container {
    width: 300px !important;
  }

  .rw-filter-input {
    width: 290px !important;
  }

  .admin-entity-search .combo-container > div {
    width: 610px !important;
  }

  .admin-entity-search .rw-popup-transition .rw-popup .rw-filter-input {
    width: 600px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .combo-boxes > div {
    width: inherit;
  }

  .combo-container {
    float: left;
    width: 100% !important;
  }

  .combo-stabilizer {
    width: calc(100% - 22px) !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 10px !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .combo-container {
    width: 100% !important;
  }

  .combo-container > div {
    width: 100% !important;
  }
}

@media (min-width: 1024px) and (max-width: 1145px) and (orientation: portrait) {
  .admin-entity-search .combo-container > div {
    width: 610px !important;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 480px) and (max-width: 812px) {
  .combo-boxes {
    display: -webkit-flex !important;
    display: flex !important;
    width: 100% !important;
    padding-bottom: 0 !important;
  }

  .combo-boxes > div {
    /* display: flex; */
    width: 100%;
    margin: 0px;
    /* flex-direction: column; */
    /* align-items: center; */
    padding: 0 10px;
  }

  .combo-stabilizer .combo-container {
    width: 100% !important;
  }

  .combo-stabilizer:first-child > div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .combo-container > div {
    width: 100% !important;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 479px) {
  .combo-boxes {
    display: -webkit-flex !important;
    display: flex !important;
    width: 100% !important;
    padding-bottom: 0 !important;
    margin-bottom: -1px;
  }

  .combo-boxes > div {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin: 0px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10px;
  }

  .combo-stabilizer .combo-container {
    width: 100% !important;
  }

  .combo-stabilizer:first-child > div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .combo-container > div {
    width: 100% !important;
  }
}

.ErrorView_container__1OC03 {
  padding: 20px;
}

.ErrorView_title__26ilg {
  font-size: 22px;
  text-align: center;
  font-style: bold;
  padding-bottom: 10px;
}

.ErrorView_text__1dQoB {
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  color: red;
  font-style: italic;
}

.ErrorView_code__1aJeq {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: red;
  font-style: bold;
}

.ErrorView_stack__3Lslq {
  padding: 20px;
}

.ErrorView_error__17n1Y > a {
  text-decoration: underline;
  margin-left: 2px;
}

.ErrorView_buttonContainer__3YOmk {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.EntityComboboxModal_customModal__3cxFp {
  background: #fff;
  max-width: 450px;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  padding: 0 !important;
}

.EntityComboboxModal_customOverlay__1c8k8 {
  padding: 0 !important;
}

.EntityComboboxModal_closeButton__2eSjI {
  background: #0070b9;
  border-radius: 100%;
  padding: 5px;
  box-sizing: border-box;
  width: 41.25px;
  height: 41.25px;
  fill: #fff;
}

.EntityComboboxModal_heading__fWOtR {
  color: #021640;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  margin-bottom: 25px;
}

.EntityComboboxModal_inputContainer__YAejs {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 1em 0 0;
}

.EntityComboboxModal_input__2Z2T6 {
  padding: 1em;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  margin: 0 20px;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

.EntityComboboxModal_searchClear__1k4ZA {
  position: absolute;
  left: 93.5%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  padding-right: 20px;
}

.EntityComboboxModal_searchClearIcon__3XIpC {
  width: 30px;
  height: 30px;
}
.EntityComboboxModal_title__b4U4x {
  border-bottom: 2px solid #ccc;
  background: #fff;
  padding: 20px 20px 5px 25px;
  margin: 0;
  color: #0070b9;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.EntityComboboxModal_entity__2Vcvh {
  color: #021640;
  border-bottom: 2px solid #ccc;
  box-sizing: border-box;
  padding: 15px 0 15px 35px;
  background: #fff;
}

.EntityComboboxModal_account__2WgRR {
  margin-right: 5px;
}

.EntityComboboxModal_entity__2Vcvh:hover {
  background: #0070b9;
  transition: 0.3s all ease-in;
  color: #fff;
}

.EntityComboboxModal_entityDataContainer__YhRlG {
  background: red;
  margin-top: 5px;
  margin-bottom: 1em;
}

.EntityComboboxModal_noAccountMatch__XEyBL {
  color: #021640;
  border-top: 2px solid #ccc;
  padding: 10px 30px 0 29px;
  margin-top: 30px;
  font-size: 22px;
  font-family: 'Lato', sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.EntityComboboxModal_clearSearchText__3DwAo {
  font-family: 'Lato', sans-serif;
  text-align: center;
  color: red;
}

/*
  In case we're ever interested in having
  custom animation. Simply add these classnames
  to the Modal's classnames prop
*/
.EntityComboboxModal_transitionEnter__11L1G {
  -webkit-transform: scale(0);
          transform: scale(0);
}
.EntityComboboxModal_transitionEnterActive__12Ecq {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 100ms ease-in-out;
  transition: transform 100ms ease-in-out;
  transition: transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
}
.EntityComboboxModal_transitionExit__2wErL {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.EntityComboboxModal_transitionExitActive__33QqU {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .EntityComboboxModal_searchClearIcon__3XIpC {
    width: 23px;
    height: 23px;
  }

  .EntityComboboxModal_input__2Z2T6 {
    font-size: 14px;
  }

  .EntityComboboxModal_searchClear__1k4ZA {
    left: 90.5%;
    top: 55%;
  }

  .EntityComboboxModal_heading__fWOtR {
    font-size: 18px;
  }

  .EntityComboboxModal_closeButton__2eSjI {
    width: 40px;
    height: 40px;
  }

  .EntityComboboxModal_noAccountMatch__XEyBL {
    font-size: 17px;
  }
}

.DashboardDataViewer_corner-bottom-logo__3fxpe {
  position: relative;
  float: right;
  bottom: 25px;
  right: 30px;
  height: 40px;
  max-width: 100%;
  width: auto;
  padding: 20px 28px;
  margin: 25px 25px 25px 0;
}

.DashboardDataViewer_underline__3lbGY {
  border-bottom: solid 1px #ccc8;
  border-top: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.DashboardDataViewer_date-text__1d-Hq {
  font-size: 12px;
  font-weight: 300;
  color: #c4c4c4;
}

.DashboardDataViewer_dataRow__3NI1- {
  height: auto;
  width: calc(90% + 60px);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.DashboardDataViewer_dataRow__3NI1-:before {
  width: 100%;
  content: '';
  height: 100%;
  z-index: -10;
  position: absolute;
  transition: 1s linear;
  background-color: #f1f2f7;
  opacity: 0;
}

.DashboardDataViewer_dataRowLoading__2PWRi:before {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f2f7;
  z-index: 1;
  opacity: 0.7;
}

.DashboardDataViewer_dataRow__3NI1-.DashboardDataViewer_loading__3h__s {
  position: relative;
}

.DashboardDataViewer_dataRow__3NI1- > div:not(.DashboardDataViewer_separator__2qxYf) .DashboardDataViewer_blue-block__3nkQu .DashboardDataViewer_block-text__2Vm2G {
  padding-top: 24px;
}

.DashboardDataViewer_plain-text__152qL {
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
  color: #fff;
}

.DashboardDataViewer_plain-text__152qL {
  font-size: 12px;
  font-weight: 300;
  color: #c4c4c4;
}

.DashboardDataViewer_sub-header-title__1waPI {
  text-transform: none;
  font-size: 15px;
}

.DashboardDataViewer_dashboard-error-display__2D4Ib div:first-child {
  width: 100%;
  float: left;
}

.DashboardDataViewer_dashboard-error-display__2D4Ib {
  width: calc(90% + 60px);
  margin: 0 auto;
}

.DashboardDataViewer_separator__2qxYf {
  width: 50%;
  float: left;
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 30px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV {
  width: 100%;
  line-height: 24px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:first-child {
  margin-bottom: 16px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:last-child .DashboardDataViewer_block-text__2Vm2G {
  padding-bottom: 20px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:first-child .DashboardDataViewer_blue-block__3nkQu {
  height: 90px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block__3nkQu {
  height: 133px;
}

.DashboardDataViewer_blue-block__3nkQu {
  background: #001b54;
  height: 240px;
  color: white;
  float: left;
  position: relative;
  box-shadow: 0 10px 25px 10px #00000010;
  text-align: left;
  padding: 0 15px;
  box-sizing: border-box;
  font-weight: bold !important;
  cursor: pointer;
}

.DashboardDataViewer_blue-block-container__3SrZV:first-child(4n-3) {
  padding-left: 0;
}

.DashboardDataViewer_blue-block-container__3SrZV:last-child {
  padding-right: 0;
}

.DashboardDataViewer_blue-block__3nkQu .DashboardDataViewer_block-title__2mV6O {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.DashboardDataViewer_blue-block__3nkQu .DashboardDataViewer_block-text__2Vm2G {
  width: calc(100% - 30px);
}

.DashboardDataViewer_blue-block__3nkQu {
  width: 100% !important;
  border-radius: 4px;
}

.DashboardDataViewer_blue-block-container__3SrZV {
  width: 33%;
  line-height: 30px;
  box-sizing: border-box;
  padding: 0 8px 0 0;
  float: left;
}

.DashboardDataViewer_block-title__2mV6O {
  font-size: 13px;
  font-weight: 300;
}

.DashboardDataViewer_block-title__2mV6O {
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.DashboardDataViewer_block-text__2Vm2G {
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  bottom: 0;
  top: 20px;
  width: 100%;
}

.DashboardDataViewer_block-text__2Vm2G:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.DashboardDataViewer_block-text__2Vm2G > * {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.DashboardDataViewer_block-text__2Vm2G p {
  margin-top: 0;
  margin-bottom: 0;
}

.DashboardDataViewer_blue-block__3nkQu:first-child {
  margin-left: 0;
}

.DashboardDataViewer_blue-block__3nkQu {
  width: 32%;
}

.DashboardDataViewer_separator__2qxYf {
  width: 34%;
  float: left;
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 30px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV {
  width: 100%;
  line-height: 24px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:first-child {
  margin-bottom: 16px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:last-child .DashboardDataViewer_block-text__2Vm2G {
  padding-bottom: 20px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:first-child .DashboardDataViewer_blue-block__3nkQu {
  height: 90px;
}

.DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block__3nkQu {
  height: 133px;
}

.DashboardDataViewer_resetButton__r9rN6 {
  height: 38px;
  box-shadow: none;
  background: #001a4a;
  color: white;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

@media (min-width: 480px) and (max-width: 812px) {
  .DashboardDataViewer_dataRow__3NI1- {
    margin: 0 !important;
    padding: 0 !important;
    width: inherit !important;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .DashboardDataViewer_dataRow__3NI1- {
    margin: 0 !important;
    padding: 0 !important;
    width: inherit !important;
  }
}

@media (max-width: 423px) {
  .DashboardDataViewer_blue-block-container__3SrZV {
    cursor: default;
  }
}

@media (max-width: 1023px) {
  .DashboardDataViewer_dashboard-error-display__2D4Ib div:first-child {
    text-align: center;
  }

  .DashboardDataViewer_separator__2qxYf {
    width: 100%;
    clear: both;
    padding: 0;
  }

  .DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:first-child,
  .DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:last-child {
    margin-bottom: 0;
    padding: 0 10px;
  }

  .DashboardDataViewer_blue-block-container__3SrZV:last-child {
    padding-right: 10px;
  }

  .DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block__3nkQu {
    height: 110px;
  }

  .DashboardDataViewer_separator__2qxYf .DashboardDataViewer_blue-block-container__3SrZV:last-child .DashboardDataViewer_block-text__2Vm2G {
    top: 40px;
    padding-bottom: 0;
  }

  .DashboardDataViewer_blue-block-container__3SrZV {
    padding: 0 10px;
    box-sizing: border-box;
    max-width: 100vw;
  }

  .DashboardDataViewer_block-title__2mV6O,
  .DashboardDataViewer_block-text__2Vm2G {
    text-align: left;
  }

  .DashboardDataViewer_blue-block-container__3SrZV {
    width: 100%;
    padding: 0 10px 0 10px;
    cursor: pointer;
  }

  .DashboardDataViewer_blue-block__3nkQu .DashboardDataViewer_block-title__2mV6O,
  .DashboardDataViewer_blue-block__3nkQu .DashboardDataViewer_block-text__2Vm2G {
    text-align: left;
  }

  .DashboardDataViewer_blue-block__3nkQu {
    width: 100%;
    margin-left: 0;
    height: 248px;
    min-height: 100px;
    float: none;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 15px;
  }

  .DashboardDataViewer_data-row__ZNcII {
    padding: 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }

  .DashboardDataViewer_corner-bottom-logo__3fxpe {
    bottom: 0;
    height: 40px;
    max-width: 100%;
    width: auto;
    padding: 20px 28px;
  }

  .DashboardDataViewer_resetButton__r9rN6 {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .DashboardDataViewer_block-title__2mV6O.DashboardDataViewer_small__1ZS3X {
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .DashboardDataViewer_blue-block-container__3SrZV {
    padding: 0 15px 0 0;
  }

  .DashboardDataViewer_separator__2qxYf {
    padding-right: 15px;
  }
}

/* Grey block container (Note this was changed to blue) */
.DashboardDataBlock_grey-block-container__2KWvR:nth-child(4n-3) {
  padding-left: 0;
}

.DashboardDataBlock_grey-block-container__2KWvR:nth-child(4n) {
  padding-right: 0;
}

.DashboardDataBlock_grey-block__18mQz {
  background: #1072b7;
  width: 100%;
  margin-top: 16px;
  margin-left: 1.5%;
  height: 150px;
  color: white;
  float: left;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 25px 10px #00000010;
  border-radius: 4px;
}

.DashboardDataBlock_negative-block__1IgBx {
  background: #1072b7;
  width: 100%;
  margin-top: 16px;
  margin-left: 0%;
  height: 135px;
  color: white;
  float: left;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 25px 10px #00000010;
  border-radius: 4px;
}

.DashboardDataBlock_grey-block-container__2KWvR {
  width: 25%;
  box-sizing: border-box;
  padding: 0 8px;
  float: left;
}

.DashboardDataBlock_grey-block__18mQz .DashboardDataBlock_block-title__1KD3v {
  text-align: left;
  text-transform: uppercase;
  padding: 10px 16px;
  margin: 0;
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: rgb(0, 27, 84);
  cursor: pointer;
}

.DashboardDataBlock_grey-block__18mQz:first-child {
  margin-left: 0;
}

.DashboardDataBlock_block-title__1KD3v {
  text-align: left;
  text-transform: uppercase;
  padding: 10px 16px;
  margin: 0;
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: rgb(0, 27, 84);
  cursor: pointer;
}

.DashboardDataBlock_block-text-small__1tYok {
  font-size: 15px;
  font-weight: bold;
  bottom: 0;
  top: 20px;
  width: 100%;
  cursor: pointer;
}

.DashboardDataBlock_block-text-small__1tYok:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.DashboardDataBlock_block-text-small__1tYok > * {
  display: inline-block;
  vertical-align: middle;
}

.DashboardDataBlock_block-text-small__1tYok p {
  margin-top: 0;
  margin-bottom: 0;
}

.DashboardDataBlock_block-text-small__1tYok > div {
  width: 100%;
  padding: 0px 16px;
  text-align: left;
  box-sizing: border-box;
}

.DashboardDataBlock_block-text-small__1tYok > div > div:not(:last-child) {
  padding-bottom: 4px;
  border-bottom: solid 1px #fff8;
  margin-bottom: 4px;
}

.DashboardDataBlock_plain-text__JrBbx {
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
  color: #fff;
}

.DashboardDataBlock_negativeTradingText__3dP9E {
  font-size: 15px;
  font-weight: 300;
  color: #fff;
}

.DashboardDataBlock_negative-grey-block___pVNE {
  width: 100%;
  padding: 0;
}

.DashboardDataBlock_negative-summary__11nrV {
  line-height: 19px;
}

/* Dashboard-update setup */
.DashboardDataBlock_grey-block__18mQz {
  width: 100%;
  float: none;
}

.DashboardDataBlock_grey-block-details-closed__3h-KA {
  display: none;
}

.DashboardDataBlock_grey-block-details-open__SnjFb {
  display: block;
  height: auto;
  width: 96%;
  margin: 0 auto;
}

@media (max-width: 553px) {
  .DashboardDataBlock_negative-summary__11nrV {
    min-height: 0;
    min-height: initial;
    height: auto;
    height: initial;
  }
  .DashboardDataBlock_negative-summary__11nrV .DashboardDataBlock_block-text-small__1tYok {
    margin-bottom: 18px;
  }
}

@media (max-width: 1023px) {
  .DashboardDataBlock_negative-block__1IgBx {
    margin-top: 0;
  }

  .DashboardDataBlock_grey-block-container__2KWvR {
    width: 100%;
    padding: 10px 10px 0 10px !important;
    margin: 0;
    max-width: 100vw;
    box-sizing: border-box;
  }

  .DashboardDataBlock_grey-block__18mQz {
    width: 100%;
    margin: 0;
    height: 150px;
    min-height: 100px;
    float: none;
    text-align: center;
    position: relative;
    overflow: hidden;
  }

  .DashboardDataBlock_grey-block-container__2KWvR:first-child {
    padding-left: 10px;
    margin-top: 0px;
  }

  .DashboardDataBlock_grey-block-container__2KWvR:nth-child(4n) {
    padding-right: 10px;
  }

  .DashboardDataBlock_grey-block-container__2KWvR:nth-child(4n-3) {
    padding-left: 10px;
  }

  .DashboardDataBlock_grey-block-container__2KWvR:focus {
    outline: none;
  }

  .DashboardDataBlock_block-title__1KD3v,
  .DashboardDataBlock_block-text__jF8CN {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .DashboardDataBlock_block-title__1KD3v.DashboardDataBlock_small__1dgSB {
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.mobileUl_listStyle__1PvOb {
  list-style: none;
  display: block;
  padding-left: 0;
  margin-top: 40px;
}

.mobileUl_listStyleFirstChild__Saeqq {
  cursor: pointer;
  font-size: 16px;
  border-bottom: 2px solid #bcbcbc;
  border-top: 2px solid #bcbcbc;
  padding: 15px;
  padding-left: 35px;
  text-transform: uppercase;
}

.mobileUl_listStyleChildren__1pNIn {
  cursor: pointer;
  font-size: 16px;
  border-bottom: 2px solid #bcbcbc;
  box-sizing: border-box;
  padding: 15px;
  padding-left: 35px;
  text-transform: uppercase;
}

.mobileUl_listStyleChildren__1pNIn:hover {
  cursor: pointer;
  background-color: #0070b9;
  color: #fff;
}

.mobileUl_listStyleFirstChild__Saeqq:hover {
  cursor: pointer;
  background-color: #0070b9;
  color: #fff;
}

.mobileUl_closeIconSize__2dzR7 {
  width: 30px;
  margin-top: 5px;
}

.mobileUl_mobileSearch__19HzP {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  z-index: 10;
  overflow: auto;
}

.mobileUl_mobileSearchHeading__ejthz {
  color: #021640;
  text-align: center;
  font-family: 'Lato';
  font-weight: 700;
  margin-bottom: 25px;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .mobileUl_mobileSearchHeading__ejthz {
    font-size: 18px;
  }
}

.DashboardSpokePerformance_container__30lLS {
  width: calc(90% + 60px);
  margin: 0 auto 20px;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.4s;
  border: 1px solid #c1cbd1;
}

.DashboardSpokePerformance_containerClosed__-iBjm {
  height: 50px;
}

.DashboardSpokePerformance_containerOpen__3pl2Z {
  overflow: hidden;
}

.DashboardSpokePerformance_header__3Bv_M {
  position: relative;
  width: 100%;
  height: 50px;
  background: transparent;
  color: #041d51;
  cursor: pointer;
  padding: 15px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
}

.DashboardSpokePerformance_dataWrapper__2uXGo {
  text-align: left;
  padding: 15px;
}

.DashboardSpokePerformance_spokeBlock__im-sk {
  text-align: left;
  width: calc(50% - 10px);
  display: inline-block;
  overflow: hidden;
  margin: 0;
  color: #041d51;
  padding: 0;
}

.DashboardSpokePerformance_worstBlock__2e15E {
  margin-left: 20px;
}

.DashboardSpokePerformance_rankBlock__3nwZk {
  width: 100%;
  margin: 0;
  background: #3172b2;
  padding: 10px;
  color: white;
  border-radius: 4px;
  box-sizing: border-box;
}

.DashboardSpokePerformance_spokeText__1A6tv {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
}

.DashboardSpokePerformance_rankText__3bxz2 {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.DashboardSpokePerformance_boldText__3yIsI {
  font-size: 15px;
  font-weight: bold;
  margin-right: 7px;
}

.DashboardSpokePerformance_rankItem__B3ii2 {
  width: 99%;
  border-bottom: 1px solid #fff8;
  text-align: left;
  padding: 7px 0;
  cursor: pointer;
}

.DashboardSpokePerformance_rankItem__B3ii2:last-of-type {
  border: none;
}

.DashboardSpokePerformance_chevron__1doq8 {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 20px;
}

@media (max-width: 1023px) {
  .DashboardSpokePerformance_spokeBlock__im-sk {
    display: block;
    width: 100%;
  }
  .DashboardSpokePerformance_worstBlock__2e15E {
    margin-top: 20px;
    margin-left: 0;
  }

  .DashboardSpokePerformance_container__30lLS {
    margin: 50px auto 0;
    /*margin-bottom: 50px;*/
    width: 94%;
  }
}

@media (max-width: 812px) {
  .DashboardSpokePerformance_container__30lLS {
    margin: 15px auto 0;
    width: 96.5%;
  }
}

.DashboardProductsReport_root__aE8HI {
  width: calc(90% + 60px);
  margin: 0 auto 20px;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.4s;
  border: 1px solid #c1cbd1;
}

.DashboardProductsReport_rootClosed__1BooT {
  height: 50px;
}

.DashboardProductsReport_header___c7zn {
  position: relative;
  width: 100%;
  height: 50px;
  background: transparent;
  color: #041d51;
  cursor: pointer;
  padding: 15px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
}

.DashboardProductsReport_container__1LRe7 {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px 15px;
  box-sizing: border-box;
}

.DashboardProductsReport_subHeader__3-KHN {
  margin-top: 0;
  color: #001b54;
}

.DashboardProductsReport_dataBlock__2hqAu {
  position: relative;
  width: 100%;
  background: #3172b2;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
}

.DashboardProductsReport_dataRow__37nRC {
  position: relative;
  color: white;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.DashboardProductsReport_dataRow__37nRC:last-child {
  border-bottom: none;
}

.DashboardProductsReport_innerRow__3O5mX {
  position: relative;
  width: 100%;
}

.DashboardProductsReport_innerRow__3O5mX > p {
  position: relative;
  display: inline-block;
  margin: 14px 0 5px;
}

.DashboardProductsReport_innerRow__3O5mX > p:first-child {
  width: 40%;
}

.DashboardProductsReport_amount__1axB_ {
  text-align: right;
  padding-right: 50px;
  box-sizing: border-box;
  width: 60%;
}

.DashboardProductsReport_chevron__2ppOG {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 20px;
}

.DashboardProductsReport_chevronYellow__2LRNd {
}

.DashboardProductsReport_separator__1qMA2 {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 3px;
  background: #36bd74;
}

.DashboardProductsReport_salesCount__3izCx {
  font-size: 11px;
  margin: 0 7px 0 0;
}

@media (max-width: 1024px) {
  .DashboardProductsReport_root__aE8HI {
    margin: 15px auto 0;
    width: 94.3%;
  }
}

@media (max-width: 812px) {
  .DashboardProductsReport_root__aE8HI {
    width: 96%;
  }
}

.errorDisplay_error__3SNxm {
  color: red;
}

.DashboardNewProductsModal_root__1FZZ7 {
  position: fixed;
  bottom: 20px;
  left: 70px;
  right: 0;
  margin: 0 auto;
  max-width: 90%;
  min-height: 50px;
  max-height: 96vh;
  background: #f1f2f7;
  border: 1px solid #c1cbd1;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
}

.DashboardNewProductsModal_rootClosed__3E9cS {
  position: relative;
  left: 0;
  bottom: 0;
  margin: 10px auto;
  width: 100%;
  max-width: calc(90% + 60px);
}

.DashboardNewProductsModal_root__1FZZ7 > div {
  max-height: 96vh;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: auto;
}

.DashboardNewProductsModal_root__1FZZ7 > div > div {
  margin-left: 0;
  max-width: 100%;
  max-height: 95vh;
  box-sizing: border-box;
  min-height: 96vh;
}

.DashboardNewProductsModal_root__1FZZ7 > div > div > img {
  display: none;
}

.DashboardNewProductsModal_header__OVUdW {
  position: relative;
  height: 100%;
  width: 100%;
  background: #fcd700;
  padding: 0 10px;
  cursor: pointer;
}

.DashboardNewProductsModal_header__OVUdW p {
  margin: 0;
  line-height: 50px;
  font-size: 15px;
  font-weight: bold;
  color: #001854;
  width: 80%;
}

.DashboardNewProductsModal_header__OVUdW img {
  position: absolute;
  height: 20px;
  right: 20px;
  top: 15px;
}

.DashboardNewProductsModal_openImage__3mQKM {
  height: 20px;
  position: absolute;
  right: 50px;
  top: 20px;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 1023px) {
  .DashboardNewProductsModal_root__1FZZ7 {
    /*display: none;*/
    width: 100%;
    max-width: 100vw;
    bottom: 0;
    left: 0;
    height: calc(100% - 55px);
    top: 55px;
    z-index: 2;
    overflow: hidden;
    box-sizing: border-box;
  }

  .DashboardNewProductsModal_root__1FZZ7 > div > div > div:first-child p {
    padding: 0;
  }

  .DashboardNewProductsModal_root__1FZZ7 > div > div > img {
    display: block;
  }

  .DashboardNewProductsModal_rootClosed__3E9cS {
    background: white;
    width: calc(90% + 35px);
    height: 40px;
    min-height: 0;
    top: 0;
  }

  .DashboardNewProductsModal_rootClosed__3E9cS > div {
    overflow: hidden;
  }

  .DashboardNewProductsModal_header__OVUdW {
    background: white;
  }

  .DashboardNewProductsModal_header__OVUdW p {
    line-height: 40px;
  }

  .DashboardNewProductsModal_header__OVUdW img {
    top: 10px;
  }

  .DashboardNewProductsModal_root__1FZZ7 > div > div {
    padding-top: 0;
    min-height: calc(100vh - 90px);
    overflow-x: hidden;
    max-height: none;
  }

  .DashboardNewProductsModal_openImage__3mQKM {
    position: relative;
    margin: 10px 0 0;
    float: right;
    clear: both;
    fill: red;
    top: 0;
    right: 5%;
  }
}

@media (max-width: 812px) {
  .DashboardNewProductsModal_rootClosed__3E9cS {
    width: calc(100% - 20px);
  }
}

.LoadingMask_container__1pIoZ {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.LoadingMask_content__3LL0s {
  padding-top: -40px;
}

.ViewProducts_body__eRD7j {
  position: relative;
  min-height: 100vh;
  margin-left: 70px;
  width: calc(100vw - 70px);
  overflow: auto;
  padding-bottom: 70px;
  box-sizing: border-box;
}

.ViewProducts_heading__1U6sz {
  font-size: 24px;
  color: #021640;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
}

.ViewProducts_heading__1U6sz > .ViewProducts_productHeader__3OEGj {
  width: calc(90% + 60px);
  margin: 0 auto;
  padding-top: 20px;
  font-size: 24px;
  text-transform: uppercase;
}

.ViewProducts_noContent__2syPd {
  margin: auto;
  text-align: center;
  font-size: 14px;
}

.ViewProducts_cornerLogo__1m7rB {
  position: absolute;
  height: 40px;
  bottom: 25px;
  right: 30px;
}

@media screen and (max-width: 1023px) {
  .ViewProducts_body__eRD7j {
    padding-top: 50px;
    margin-left: 0;
    width: 100vw;
  }

  .ViewProducts_heading__1U6sz {
    text-align: center;
  }
}

.ProductTile_body__1Yi8D {
  position: relative;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
  border: 1px solid #c1cbd1;
  border-radius: 4px;
  transition: 0.3s;
  box-sizing: border-box;
}

.ProductTile_bodyClosed__ecN1J {
  min-height: 50px;
  height: auto;
  overflow: hidden;
}

.ProductTile_bodyOpen__3HWZ_ {
  min-height: 220px;
  height: auto;
  overflow: hidden;
  padding-bottom: 20px;
}

.ProductTile_header__2fHNz {
  position: relative;
  width: 100%;
  min-height: 50px;
  height: auto;
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
}

.ProductTile_date__2kxqf {
  position: absolute;
  right: 0;
  top: 0;
  background: #fcd102;
  line-height: 100%;
  height: 100%;
  max-height: 100%;
  width: 280px;
  text-align: right;
  padding-right: 25px;
  box-sizing: border-box;
  cursor: pointer;
}

.ProductTile_date__2kxqf::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.ProductTile_date__2kxqf > * {
  display: inline-block;
  vertical-align: middle;
}

.ProductTile_dateOpen__2Tx3W {
  background: transparent;
}

.ProductTile_dateClosed__2yPfr {
  box-sizing: content-box;
  border-left-width: 25px;
  border-left-style: solid;
  border-image: linear-gradient(
      to bottom right,
      #f1f2f7 0%,
      #f1f2f7 50%,
      #fcd102 50%
    )
    1 100%;
}

.ProductTile_title__xqtbR {
  display: inline-block;
  height: auto;
  line-height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 15px;
}

.ProductTile_chevron__2HpO_ {
  max-height: 15px;
  max-width: 15px;
  box-sizing: border-box;
  margin-left: 10px;
  margin-bottom: -2px;
  cursor: pointer;
}

.ProductTile_contentBody__2ul9A {
  position: relative;
  padding: 20px 10px;
  margin-top: 8px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.ProductTile_descriptionBlock__352kJ {
  display: inline-block;
  max-width: 100%;
  top: 0;
  min-height: 100%;
  word-wrap: break-spaces;
  overflow: hidden;
  height: auto;
  position: relative;
}

.ProductTile_buttonContainer__3r3yR {
  height: 48px;
  overflow: visible;
  cursor: pointer;
  display: block;
  position: relative;
  line-height: 48px;
  margin-top: 16px;
  width: auto;
}

.ProductTile_button__3lcLc {
  background: #001a4a;
  position: relative;
  color: #fff;
  text-decoration: none;
  height: 48px;
  max-height: 48px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  box-sizing: border-box;
  line-height: 48px;
  border-radius: 4px;
  border-width: 1px;
}

.ProductTile_description__2mevV {
  margin: 0;
  height: auto;
  overflow: hidden;
}

.ProductTile_image__BT4oM {
  display: inline-block;
  margin-right: 40px;
  max-width: 45%;
}

.ProductTile_deleteButton__1iFbk {
  top: 10px;
  left: 10px;
}

.ProductTile_descriptionBlockHalf__3rHxf {
  max-width: 50%;
  position: absolute;
  top: 20px;
}

.ProductTile_mobileTitle__ttd5j {
  display: none;
}

.ProductTile_mobileTitleClosed__2Q_1z {
}

.ProductTile_mobileImage__3XlAA {
  display: none;
}

.ProductTile_editButton__TKxm- {
  margin-left: 7px;
}

@media (max-width: 1023px) {
  .ProductTile_bodyClosed__ecN1J {
    height: auto;
  }

  .ProductTile_image__BT4oM {
    display: none;
  }

  .ProductTile_deleteButton__1iFbk {
    display: none;
  }

  .ProductTile_editButton__TKxm- {
    display: none;
  }

  .ProductTile_descriptionBlock__352kJ {
    max-width: 100%;
    position: relative;
  }

  .ProductTile_descriptionBlockHalf__3rHxf {
    max-width: 100%;
  }

  .ProductTile_date__2kxqf {
    width: 100%;
    max-width: 100%;
    text-align: right;
    position: relative;
    height: 50px;
    padding-right: 15px;
    box-sizing: border-box;
    font-size: 12px;
    border: none;
  }

  .ProductTile_date__2kxqf > p {
    line-height: 100%;
    font-size: 12px;
  }

  .ProductTile_date__2kxqf > img {
    margin-bottom: 0;
  }

  .ProductTile_title__xqtbR {
    display: none;
  }

  .ProductTile_mobileImage__3XlAA {
    display: block;
    max-width: 95%;
    position: relative;
    margin: 0 auto;
  }

  .ProductTile_mobileTitle__ttd5j {
    display: block;
    max-width: 100%;
    word-wrap: break-word;
    text-align: left;
    line-height: 15px;
    padding: 0 10px;
  }

  .ProductTile_mobileTitle__ttd5j:first-line {
    line-height: 50px;
  }

  .ProductTile_mobileTitleClosed__2Q_1z {
    line-height: 30px;
  }

  .ProductTile_header__2fHNz {
    height: auto;
    padding-left: 0;
  }
}

@media (max-width: 600px) {
  .ProductTile_body__1Yi8D {
    height: auto;
    padding-bottom: 30px;
    -webkit-tap-highlight-color: transparent;
  }

  .ProductTile_bodyClosed__ecN1J {
    height: auto;
    overflow: hidden;
    padding-bottom: 0;
  }

  .ProductTile_title__xqtbR {
    width: 100%;
  }

  .ProductTile_date__2kxqf p {
    margin-left: 0;
  }

  .ProductTile_dateClosed__2yPfr {
    width: 100%;
    border: none;
    padding-right: 10px;
  }

  .ProductTile_dateClosed__2yPfr p {
    margin-left: 25px;
  }

  .ProductTile_contentBody__2ul9A {
    position: relative;
    height: auto;
    margin-top: 0;
    padding-top: 0;
  }

  .ProductTile_descriptionBlock__352kJ {
    height: auto;
    max-width: 100%;
    word-wrap: break-word;
    position: relative;
    display: block;
  }

  .ProductTile_descriptionBlockHalf__3rHxf {
    max-width: 100%;
  }

  .ProductTile_image__BT4oM {
    margin-right: 10px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
}

.CreateProduct_body__tlfPt {
  position: relative;
  min-height: 100vh;
  margin-left: 70px;
  min-width: calc(100vw - 70px);
  overflow: auto;
  box-sizing: border-box;
}

.CreateProduct_heading__3YEtT {
  font-size: 24px;
  color: #021640;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
}

.CreateProduct_heading__3YEtT > .CreateProduct_productHeader__2RR0y {
  width: calc(90% + 60px);
  margin: 0 auto;
  padding-top: 20px;
}

.CreateProduct_formContainer__3gsG6 {
  position: relative;
  width: 50%;
  text-align: left;
  margin: auto;
  overflow: hidden;
  text-shadow: 0 0 black;
}

.CreateProduct_textAreaContainer__3a4s8 > div > textarea {
  background-color: white !important;
  border-radius: 3px;
  resize: none;
  height: 225px;
  border: 1px solid #707071;
}

.CreateProduct_inputContainer__1UM-u {
  position: relative;
  width: 100%;
  min-height: 70px;
}

.CreateProduct_inputContainer__1UM-u input[type='date'] {
  position: relative;
  height: 100%;
  min-height: 47px;
  width: 100%;
  border: 1px solid #707071;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 6px;
  font-size: 14px;
  color: #707071;
}

.CreateProduct_label__1qE9X {
  font-size: 14px;
  color: #021640;
  margin-bottom: 5px;
  margin-top: 8px;
  text-align: left;
}

.CreateProduct_textAreaDescr__Fy91J {
  border: 1px solid #707071;
  border-radius: 4px;
  height: 100px;
  width: 100%;
  background-color: white;
  line-height: 17px;
  padding: 5px 15px;
  font-size: 14px;
  outline: none;
  color: #001b54;
  bottom: 0;
  box-shadow: none;
  resize: none;
  box-sizing: border-box;
}

.CreateProduct_productInput__3rGZ5 {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #707071;
  box-sizing: border-box;
  background: white;
  color: #707071;
  height: 47px;
  margin: 0 auto;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  padding: 6px;
}

.CreateProduct_errorInput__fej6w {
  border-color: red;
}

.CreateProduct_errorDescr__3F2hn {
  border-color: red;
}

.CreateProduct_errorText__1Y9sz {
  font-size: 14px;
  color: red;
  margin-top: 5px;
}

.CreateProduct_submit__UhhwU {
  margin-top: 18px;
  height: 50px;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
  background-color: #041d51;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .CreateProduct_body__tlfPt {
    padding-top: 50px;
    margin-left: 0;
  }

  .CreateProduct_heading__3YEtT {
    text-align: center;
    font-weight: bold;
  }

  .CreateProduct_productHeader__2RR0y {
    font-size: 24px;
  }

  .CreateProduct_formContainer__3gsG6 {
    min-width: 400px;
  }
}

.ProductEditModal_root__H_GOt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.ProductEditModal_body__2rRFp {
  position: relative;
  height: 80%;
  width: 80%;
  margin: 5% 10%;
  background: white;
  overflow: auto;
}

.ProductEditModal_oldForm__1r_Pa {
  position: relative;
  height: 100%;
  width: 100%;
}

.ProductEditModal_oldForm__1r_Pa * {
  max-width: 100%;
}

.ProductEditModal_oldForm__1r_Pa > div {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  height: auto;
  padding-bottom: 15px;
}

.ProductEditModal_title__3ZGHL {
  margin: 0 auto;
  width: 90%;
  padding-top: 20px;
}

.ProductEditModal_oldForm__1r_Pa > div > div > p {
  display: none;
}

.ProductEditModal_oldForm__1r_Pa input[type='submit'] {
  /*display: none;*/
}

.ProductEditModal_button__3FNFi {
  margin: 0 25%;
  height: 50px;
  border: none;
  outline: none;
  width: 50%;
  border-radius: 4px;
  background-color: #041d51;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}

.ProductEditModal_buttonContainer__BZdZk {
}

.modal-body {
  margin: 5% 15vw;
  width: 70vw;
}

.modal-body.drilldown {
  background-color: #fff;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.drilldown .data-viewer .rw-dropdown-list {
  width: 300px;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-bottom: 10px;
  z-index: 1;
}

.data-viewer .rt-th.rt-resizable-header.-cursor-pointer {
  background: #001b54;
  color: white;
}

.data-viewer .ReactTable .rt-thead .rt-th:not(:last-child),
.ReactTable .rt-thead .rt-td:not(:last-child),
.data-viewer .ReactTable .rt-tbody .rt-td:not(:last-child) {
  border-right: 1px solid rgb(231, 233, 235);
}

.data-viewer .ReactTable.-striped .rt-tr.-odd {
  background: #ced3de;
}

.data-viewer .table-cell {
  margin: 0;
  text-align: left;
}

.data-viewer h4,
.data-viewer .last-update {
  margin-bottom: 10px;
  text-align: center;
}

.data-viewer.sk-spinner {
  margin: 100px auto !important;
}

.data-viewer select {
  font-size: 16px;
  color: #001b54;
  height: 30px;
  padding: 5px 12px 5px 30px;
  width: 207px;
  margin-bottom: 10px;
  border-radius: 0;
}

.data-viewer select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.data-viewer select {
  background-image: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) 50%, calc(100% - 15px) 50%, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.data-viewer select:focus {
  background-image: linear-gradient(45deg, black 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, black 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 15px) 50%, calc(100% - 20px) 50%, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

@media (max-width: 1023px) {
  .modal-body {
    margin: 60px 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 90vh !important ;
  }
}

.DashboardViewDataModal_horizontal-scrolling__1h2rF {
  color: #001b54;
  text-align: center;
  margin-bottom: 13px;
}

@media screen and (min-width: 1023px) {
  .DashboardViewDataModal_horizontal-scrolling__1h2rF {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .DashboardViewDataModal_horizontal-scrolling__1h2rF {
    font-size: 15px;
  }
}

.EntityController {
  margin-left: auto !important;
}

.DateRangePicker {
  margin: 0 !important;
}

button.DateRangePickerInput_calendarIcon.DateRangePickerInput_calendarIcon_1 {
  padding: 0;
  margin: 0;
}

.DateRangePickerInput,
.DateRangePickerInput,
.DateInput {
  background-color: #f1f2f7 !important;
}

.DateRangePicker_picker__directionLeft {
  left: 40px !important;
  top: 67px !important;
}

.DateRangePickerInput__withBorder {
  border: none !important;
}

.DateInput_fang {
  top: 57px !important;
  left: 50px;
}

.Tour_tourContainer__WjuJU {
  position: absolute;
  bottom: 77px;
  left: 0;
  right: 0;
  z-index: 999;
  background: white;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: 0.45s;
}

.Tour_tourItemContainer__1wZM8 {
  margin-top: 0;
  min-height: 100vh;
}

/*.tourItemContainer > span {*/
/*position: absolute;*/
/*bottom: 0;*/
/*left: 0;*/
/*right: 0;*/
/*}*/

.Tour_close__2uRcm {
  position: fixed;
  z-index: 9999;
  height: 32px;
  width: 33.7px;
  right: 0;
  margin-right: 25px;
  margin-top: 20px;
  background: #0070b9;
  border-radius: 20px;
  box-sizing: border-box;
}

.Tour_close__2uRcm svg {
  width: 34px;
  height: 31px;
}

.Tour_tourItem__2WFcS {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -30%);
          transform: translate(-50%, -30%);
  top: 30%;
  width: 100%;
  min-height: 100vh;
}

.Tour_tourItem__2WFcS > span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Tour_tourItem__2WFcS img {
  margin-top: 100px;
  width: 25%;
}

.Tour_heading__1KtBP {
  font-size: 2.2rem;
}

.Tour_heading__1KtBP {
  color: #0070b9;
  text-align: center;
  margin-bottom: 0;
}

p {
  color: inherit;
  font-size: 17px;
}

.Tour_welcome__3Jgp_ {
  /* padding: 0 10px;
  height: 100%;
  position: absolute; */
  padding: 0 10px;
  text-align: center;
}

.Tour_welcome__3Jgp_ + div {
  width: 100%;
}

.Tour_openTour__P-qH0 {
  top: 0;
  bottom: 0;
  border-radius: 0;
  -webkit-animation: Tour_slideUp__1jpdC 0.36s linear;
          animation: Tour_slideUp__1jpdC 0.36s linear;
}

.Tour_closeTour__3LjUQ {
  top: 100%;
}

@media screen and (min-width: 1024px) {
  .Tour_tourContainer__WjuJU {
    bottom: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .Tour_tourItem__2WFcS img {
    width: 50%;
  }
  .Tour_tourItemContainer__1wZM8 span {
    position: relative;
  }
}

@-webkit-keyframes Tour_slideUp__1jpdC {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes Tour_slideUp__1jpdC {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

.TourNav_tourNavContainer__3grpI {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #f1f2f7;
  padding: 15px 20px;
}

.TourNav_tourNavContainer__3grpI div {
  margin: 0 auto;
  margin-right: 30px;
}

.TourNav_tourNavContainer__3grpI div:last-child {
  margin-right: 0;
}
.TourNav_tourNavContainer__3grpI div button {
  margin: 0;
}

.TourNav_previous__3jCsp {
  background: #f1f2f7;
  color: #001b54;
}

.TourNav_previous__3jCsp:hover {
  background: inherit;
}

.TourNav_tourNavContainerBoundary__31t7m {
  width: 100%;
  display: block;
  position: relative;
  z-index: 9999;
}

@media screen and (min-width: 1024px) {
  .TourNav_tourNavContainerBoundary__31t7m {
    height: 7em;
  }

  .TourNav_tourNavContainer__3grpI {
    width: 50%;
    margin: 0 auto;
    border-radius: 10px;
    box-sizing: border-box;
  }
  /*.tourNavContainer div {
    width: 33%;
    margin: 0 auto;
    border: 15px solid #f1f2f7;
    box-sizing: border-box;
    border-radius: 8px;
  } */
}

.TourItem_tourItemContainer__3WMPZ {
  border-bottom: 1px solid #0070b9;
  box-sizing: border-box;
  padding-bottom: 15px;
  margin: 5px 20px 15px;
}

.TourItem_tourItemContainer__3WMPZ:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.TourItem_imageContainer__2AZO7 {
  width: 49%;
}

.TourItem_tourItemContainer__3WMPZ img {
  width: 100%;
  margin-left: 0 !important;
}

.TourItem_heading__1-VnY {
  color: #0070b9;
  font-size: 16px;
  text-transform: uppercase;
}
.TourItem_heading__1-VnY h2 {
  margin-bottom: 0;
}

.TourItem_caption__pRQiN {
  margin-bottom: 10px;
  color: #001b54;
}

.TourItem_caption__pRQiN h4 {
  margin-top: 15px;
}

.TourItem_explanations__HlZ48 {
  color: #001b54;
  margin-top: 20px;
  white-space: pre-wrap;
}

@media screen and (max-width: 1023px) {
  .TourItem_imageContainer__2AZO7 {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .TourItem_tourItemContainer__3WMPZ {
    border-bottom: none;
  }
  .TourItem_content__2AKgo {
    display: -webkit-flex;
    display: flex;
  }
  .TourItem_content__2AKgo img {
    width: 100%;
    max-height: 320px;
    height: auto;
    display: -webkit-flex;
    display: flex;
    margin-left: -12px;
  }

  .TourItem_explanations__HlZ48 {
    width: 50%;
    padding-left: 1.6em;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .TourItem_explanations__HlZ48 span {
    display: block;
    margin-bottom: 2em;
  }
  .TourItem_explanations__HlZ48 span:last-child {
    margin-bottom: 0;
  }
}

.DashboardConfirmDetailsModal_root__1rxiY {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.35);
  z-index: 9999;
  overflow-x: hidden;
}

.DashboardConfirmDetailsModal_confirmBoxBody__2xag9 {
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 400px;
  border-radius: 16px;
  text-align: center;
  padding: 16px;
}

.DashboardConfirmDetailsModal_logo__2brWv {
  max-height: 64px;
  max-width: 64px;
}

.DashboardConfirmDetailsModal_title__1Wtwn {
  font-size: 18px;
  color: #021640;
  font-weight: bold;
}

.DashboardConfirmDetailsModal_infoText__32bHA {
  font-size: 18px;
  color: #555;
  width: 100%;
  text-align: center;
}

.DashboardConfirmDetailsModal_infoTextBorder__1HyvZ {
  margin-bottom: 0;
  padding-bottom: 16px;
}

.DashboardConfirmDetailsModal_callText__1-e4Z {
  font-weight: normal;
}

.DashboardConfirmDetailsModal_editInput__17_uN {
  text-align: center;
  border: 1px solid #ccc;
  height: 36px;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.DashboardConfirmDetailsModal_button__1ZgaJ {
  height: 48px;
  width: 132px;
  background: #021640;
  color: white;
  line-height: 48px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: none;
  border: none;
}

.DashboardConfirmDetailsModal_yesNoButton__3C_Pw {
  margin: 0 7px;
}

.DashboardConfirmDetailsModal_submitButton__1vejN {
  width: auto;
  min-width: 193px;
}

.DashboardConfirmDetailsModal_spinner__2ADBl {
  margin-top: -10px;
}

.DashboardConfirmHubDetails_root__3S-7f {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 99vw;
  background: transparent;
  z-index: 9999;
  overflow: hidden;
}

.DashboardConfirmHubDetails_editRoot__2kZWP {
  background: rgba(0, 0, 0, 0.35);
}

.DashboardConfirmHubDetails_body__2RPBz {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 16px;
  overflow: hidden;
}

.DashboardConfirmHubDetails_header__3D7EL {
  /*height: 50px;*/
  position: relative;
}

.DashboardConfirmHubDetails_header__3D7EL h2 {
  margin: 0;
}

.DashboardConfirmHubDetails_closeIcon__N65Bh {
  position: absolute;
  right: 2%;
  top: -3px;
  max-height: 25px;
  max-width: 25px;
  cursor: pointer;
}

.DashboardConfirmHubDetails_editButton__PGSBK {
  height: 30px;
  width: 80px;
  background: #021640;
  color: white;
  line-height: 30px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  border: none;
}

.DashboardConfirmHubDetails_dropdown___uzNa {
  z-index: 999;
}

@media (max-width: 1024px) {
  .DashboardConfirmHubDetails_root__3S-7f p {
    margin: 20px 0;
  }
}

.DashboardConfirmHubDetails_confirmBoxBody__2AOMI {
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 400px;
  border-radius: 16px;
  text-align: center;
  padding: 16px;
}

.DashboardConfirmHubDetails_logo__2ixps {
  max-height: 64px;
  max-width: 64px;
}

.DashboardConfirmHubDetails_title__2qDmk {
  font-size: 18px;
  color: #021640;
  font-weight: bold;
}

.DashboardConfirmHubDetails_infoText__2RMLS {
  font-size: 18px;
  color: #555;
  width: 100%;
  text-align: center;
}

.DashboardConfirmHubDetails_infoTextBorder__1_8oE {
  margin-bottom: 0;
  padding-bottom: 16px;
}

.DashboardConfirmHubDetails_callText__3xAMP {
  font-weight: normal;
}

.DashboardConfirmHubDetails_editInput__2mffs {
  text-align: center;
  border: 1px solid #ccc;
  height: 36px;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.DashboardConfirmHubDetails_button__3ALiE {
  height: 48px;
  width: 132px;
  background: #021640;
  color: white;
  line-height: 48px;
  font-size: 14px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  box-shadow: none;
}

.DashboardConfirmHubDetails_yesNoButton__3E-xT {
  margin: 0 7px;
}

.DashboardConfirmHubDetails_submitButton__16CIg {
  width: auto;
  min-width: 191px;
}

.DashboardConfirmHubDetails_spinner__V6jdw {
  margin-top: -10px;
}

body {
  font-family: 'Lato', sans-serif;
  background: #f1f2f7;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

*:focus {
  outline: none;
}

a {
  cursor: pointer;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.Toastify {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.rt-th.header-title.rt-resizable-header.-sort-desc::before {
  content: '\25BC';
  float: right;
  margin-top: 9px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.rt-th.header-title.rt-resizable-header.-sort-asc::before {
  content: '\25B2';
  float: right;
  margin-top: 5px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.dashboard-container .closed-width {
  background: #f1f2f7;
}

.dashboard-container > div {
  width: 100%;
}

.outer-container {
  width: 100%;
  height: 100%;
  position: fixed !important;
  z-index: 1;
  top: 0;
  left: 0;
}

.modal-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #f1f2f799;
  z-index: 999;
}

a.base-button,
.email-fix a {
  line-height: 50px;
  width: auto;
  padding: 0 10px 0 15px;
  float: right;
  background-color: #247d4d;
  margin-bottom: 5px;
}

a.base-button:hover {
  background-color: #358e5e;
}

.fa-file-excel:before {
  content: '\F1C3';
}

span.fa.fa-file-excel {
  margin-left: 5px;
  position: relative;
  top: 0;
  left: -9px;
}

.entity-creator p {
  text-align: left;
}

.entity-creator input {
  border-bottom: none;
  width: 70%;
  margin-left: -30% !important;
}

.entity-creator input,
.entity-creator button {
  box-sizing: border-box;
  display: inline-block;
  background-color: transparent !important;
}

.entity-creator button {
  width: 30%;
  min-width: 100px;
  text-align: right;
}

.modal-body {
  position: absolute;
  height: auto;
  background: white;
  border-radius: 15px;
  border: 2px solid #0070b9;
  padding: 15px 30px;
  text-align: center;
  color: #021640;
  font-size: 18px;
  font-weight: 400;
  z-index: 1000;
}

.modal-close {
  position: absolute;
  width: 25px;
  height: 25px;
  line-height: 21px;
  top: 10px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
  color: #021640;
  font-size: 22px;
}

.header-title {
  background-color: #001b54 !important;
}

.-header .rt-th {
  border: none !important;
  border-right: none !important;
}

.-even > .rt-td {
  background-color: white !important;
}

.-odd > .rt-td {
  background-color: #e5e5ea !important;
}

.-header .rt-th {
  border-bottom: none !important;
}

.add-user-input-container {
  position: relative;
  min-height: 60px;
  text-align: left;
  margin: auto;
}

.add-user-text-input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #707071;
  box-sizing: border-box;
  color: #707071;
  height: 47px;
  margin: 0 auto;
  background: transparent;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  padding: 6px;
}

.add-user-input-container div {
  color: red;
  font-size: 14px;
}

.add-entity {
  float: right;
  font-size: 20px;
  z-index: 199;
  cursor: pointer;
  border-bottom: 2px solid #021640;
  padding: 0 10px 3px 4px;
}

#entityInput {
  width: 90%;
}

.table-cell.amount {
  text-align: left;
}

/* override the react table style so that it does not display infront of the modal */
.rt-noData {
  z-index: 0;
}

.reports .ReactTable {
  width: calc(90% + 60px);
  margin: 15px auto;
}

@media (max-width: 906px) {
  /* .edit-user */
  .table-cell {
    float: none;
  }
  .table-cell span {
    float: left;
  }
}

@media (max-width: 485px) {
  /* .edit-user */
  .table-cell {
    float: left;
  }

  .add-user-text-input {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .reports .ReactTable {
    max-width: calc(100% - 20px);
    box-sizing: border-box;
  }

  .Toastify {
    top: 55px;
  }

  .Toastify__toast-container {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 55px !important;
  }

  .last-update {
    text-align: center;
    max-width: 100%;
    margin-left: 0;
    font-size: 14px;
    margin-top: 20px;
  }

  .email-fix a {
    float: left !important;
  }
}

@media (min-width: 1024px) {
  .Toastify {
    position: absolute;
    height: auto;
    width: 100%;
    top: 0;
  }

  .Toastify__toast-container--top-right {
    position: absolute !important;
    right: 0 !important;
    margin: 0;
    top: 0 !important;
  }
}

.Routes_error__35Hyg {
  color: red;
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 30px;
  -webkit-animation: Routes_blinker__2ANta 1.5s linear infinite;
          animation: Routes_blinker__2ANta 1.5s linear infinite;
}

.Routes_errorContainer__3Jshy {
  height: 100vh;
  position: relative;
}

@-webkit-keyframes Routes_blinker__2ANta {
  0% {
    opacity: 0.08;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Routes_blinker__2ANta {
  0% {
    opacity: 0.08;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.sideBarMenu_menu-container__Q7czc {
  height: 100vh;
  background: #001b54;
  transition: width 0.2s;
  color: white;
  position: fixed;
  z-index: 3;
}

.sideBarMenu_user-display__15-yH .sideBarMenu_closed__1MSap .sideBarMenu_menu-open-icon__dl4Pt {
  display: block;
}

.sideBarMenu_badge__1wqj0 {
  width: 25px;
  height: 25px;
  color: #021640;
  background-color: #ffda02;
  border-radius: 50%;
  position: fixed;
  left: 52px;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  z-index: 1;
}

.sideBarMenu_number__n1DnM {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.sideBarMenu_user-display__15-yH {
  position: fixed;
  bottom: 20px;
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  cursor: pointer;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
}

.sideBarMenu_user-display__15-yH .sideBarMenu_open__1XWAr {
  padding: 10px 20px 0;
  text-align: left;
  width: 210px;
}

.sideBarMenu_user-display__15-yH .sideBarMenu_smaller__tBt38 {
  font-size: 13px;
}

.sideBarMenu_user-display__15-yH .sideBarMenu_smallest__3MtTR {
  font-size: 10px;
}

.sideBarMenu_user-display__15-yH p {
  display: inline;
}

.sideBarMenu_closed__1MSap {
  width: 70px;
  margin-right: 0;
}

.sideBarMenu_closed__1MSap .sideBarMenu_menu-toggle__dJorI {
  text-align: center;
  width: 40%;
}

.sideBarMenu_open__1XWAr {
  width: 250px;
  /* width:70px; */
}

.sideBarMenu_open__1XWAr .sideBarMenu_menu-open-icon__dl4Pt {
  padding-right: 20px;
}

.sideBarMenu_menu-item__YJSwx {
  padding-left: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #4d618d;
  font-size: 16px;
  padding-top: 10px;
  outline: none;
}

.sideBarMenu_menu-item__YJSwx:hover {
  cursor: pointer;
  background-color: #102b64 !important;
}

.sideBarMenu_menu-item__YJSwx p {
  display: inline-block;
  font-weight: 300;
}

.sideBarMenu_menu-logo-closed__1-xK1 {
  position: absolute;
  width: 100%;
  top: 0;
  max-width: 40px;
  padding: 15px;
}

.sideBarMenu_menu-open-icon__dl4Pt {
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
}

.sideBarMenu_menu-toggle__dJorI {
  display: none;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 21px;
  font-weight: normal;
}

.sideBarMenu_menu-toggle__dJorI:hover {
  cursor: pointer;
}

.sideBarMenu_menu-top-dark-closed__2xaZY {
  width: 100%;
  position: relative;
  padding-top: 65px;
}

.sideBarMenu_menu-item-closed__2XeAV p {
  margin: 0;
  font-weight: 300;
  font-size: 12px;
}

.sideBarMenu_menu-item-closed__2XeAV {
  text-align: center;
  /*padding-left: 15px;*/
  padding-bottom: 7px;
  border-bottom: 1px solid #4d618d;
  font-size: 16px;
  padding-top: 7px;
  position: relative;
  outline: none;
  background: #001b54;
}

.sideBarMenu_menu-item-closed__2XeAV:hover {
  cursor: pointer;
  background-color: #021640 !important;
  /*z-index: 0;*/
}

.sideBarMenu_menu-item-closed__2XeAV:hover .sideBarMenu_notificationBadgeCounter__s66bk {
  left: 13em;
  position: absolute;
  transition: all 0.35s ease;
  transition-delay: 0.15s;
}

.sideBarMenu_menu-item-closed-img__3g4VL {
  padding-bottom: 2px;
  padding-top: 2px;
}

.sideBarMenu_font-awesome-icon__2Rf_b {
  font-size: 21px;
  color: white;
  font-weight: lighter;
}

.sideBarMenu_submenu__16S9o {
  position: absolute;
  top: 0;
  left: -190px;
  opacity: 1;
  background-color: #001b54;
  width: 220%;
  transition: ease 0.5s;
  border: none;
  z-index: -1;
}

.sideBarMenu_menu-item-closed__2XeAV:hover .sideBarMenu_submenu__16S9o {
  display: block;
  opacity: 1;
  left: 70px;
  border-left: 1px solid #f1f2f7;
}

.sideBarMenu_nestedSubmenuItem__26CkB {
  position: absolute;
  z-index: -1;
  margin-top: -6px;
  left: -300px;
  opacity: 0;
}

#sideBarMenu_notifications__WBfzC {
  z-index: -10;
}

.sideBarMenu_nestedSubmenu__17bJG {
  z-index: 9999;
  width: 100%;
}

.sideBarMenu_submenu__16S9o div:last-child:hover .sideBarMenu_nestedSubmenuItem__26CkB {
  border-left: 1px solid white;
  opacity: 1;
  left: 168px;
  z-index: 9999;
  position: absolute;
  width: 100%;
  transition: all ease 0.5s;
}

.sideBarMenu_submenu__16S9o .sideBarMenu_nestedSubmenu__17bJG:last-child .sideBarMenu_menu-item-closed__2XeAV:last-child {
  border-bottom: none;
}

.sideBarMenu_submenu__16S9o .sideBarMenu_menu-item-closed__2XeAV {
  float: left;
  width: 109%;
  border-bottom-color: #fff;
  padding-bottom: 6px;
}

.sideBarMenu_submenu-img__24amW,
.sideBarMenu_submenu-span__lTRsN {
  float: left;
  line-height: 46px;
  padding-left: 8px;
  text-transform: capitalize;
  -ms-text-size-adjust: 95%;
}

.sideBarMenu_submenu-img__24amW {
  padding: 10px 0 10px 10px;
}

@media (min-width: 1024px) {
  .sideBarMenu_menu-logo-closed__1-xK1 {
    position: relative;
    padding-bottom: 20px;
  }
  .sideBarMenu_menu-top-dark-closed__2xaZY {
    padding-top: 10px;
  }
}

@media (max-width: 1023px) {
  .sideBarMenu_notifications__WBfzC {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 19px;
  }

  .sideBarMenu_notifications__WBfzC img,
  p {
    margin: 0;
  }

  .sideBarMenu_badge__1wqj0 p {
    margin: 0;
    position: relative;
  }

  .sideBarMenu_notifications__WBfzC .sideBarMenu_badge__1wqj0 {
    position: absolute;
    left: 95%;
    top: 50%;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
  }

  .sideBarMenu_menu-header__32Zcb {
    padding-left: 8px;
  }

  .sideBarMenu_menu-container__Q7czc {
    width: 100%;
    float: left;
  }

  .sideBarMenu_closed__1MSap {
    max-height: 55px;
  }

  .sideBarMenu_closed__1MSap .sideBarMenu_menu-toggle__dJorI {
    text-align: left;
    width: 38px;
    left: 12px;
    top: 8px;
  }

  .sideBarMenu_user-display__15-yH .sideBarMenu_closed__1MSap {
    line-height: 55px;
    text-align: left;
    top: 0;
    right: 20px;
    width: auto;
    padding-right: 20px;
    position: absolute;
    display: none;
  }

  .sideBarMenu_user-display__15-yH .sideBarMenu_closed__1MSap .sideBarMenu_font-awesome-icon__2Rf_b {
    margin: 0;
    padding-right: 20px;
    display: inline-block;
  }

  .sideBarMenu_menu-parent__28h5C .sideBarMenu_closed__1MSap {
    min-height: 55px;
    margin-right: 70px !important;
  }

  .sideBarMenu_user-display__15-yH .sideBarMenu_open__1XWAr {
    height: auto;
    text-align: left;
  }

  .sideBarMenu_open__1XWAr {
    height: 100vh;
    z-index: 99;
    position: fixed;
  }

  .sideBarMenu_menu-item__YJSwx:last-child {
    /* position: absolute; */
    width: 100%;
    bottom: 0;
  }

  .sideBarMenu_menu-logo-closed__1-xK1 {
    padding: 7px;
    left: 50px;
  }

  .sideBarMenu_menu-toggle__dJorI {
    display: block;
  }

  .sideBarMenu_menu-top-dark-closed__2xaZY {
    padding-top: 55px;
  }

  .sideBarMenu_menu-item-closed__2XeAV {
    display: block;
    border: none;
  }

  .sideBarMenu_user-display__15-yH {
    display: -webkit-flex;
    display: flex;
    bottom: -36px;
  }

  .sideBarMenu_menu-item-parent__3JLPI {
    transition: 0.4s;
    /* height: 278px; */
    max-height: 292px;
    width: 100%;
    overflow: hidden;
  }

  .sideBarMenu_menu-item-parent__3JLPI.sideBarMenu_closed__1MSap {
    height: 0;
    max-height: 0;
  }

  .sideBarMenu_menu-item-parent__3JLPI > div {
    padding-left: 50px;
  }

  .sideBarMenu_menu-item-parent__3JLPI > div:last-child {
    position: relative;
    bottom: unset;
  }

  .sideBarMenu_right-chevron___4G8f {
    display: inline-block;
    height: 22px;
    position: absolute;
    right: 22px;
    transition: 0.4s;
  }

  .sideBarMenu_spin__2mwDD {
    -webkit-transform: rotate3d(1, 0, 0, 180deg);
            transform: rotate3d(1, 0, 0, 180deg);
  }
}

@media screen and (max-height: 558px) {
  .sideBarMenu_user-profile__2QTUf {
    position: relative !important;
  }
}

.PleaseCallMe_root__2AdAH {
  position: relative;
  width: 100%;
}

.PleaseCallMe_modalContainer__1995I {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000090;
  z-index: 999;
}

.PleaseCallMe_modalContainer__1995I * {
  color: #001b54;
}

.PleaseCallMe_modalContainerMobile__Of79I {
  background: #fff;
}

.PleaseCallMe_modalContainerMobile__Of79I .PleaseCallMe_modalBody__14CFg {
  min-width: 312px;
}

.PleaseCallMe_modalBody__14CFg {
  position: absolute;
  height: 85%;
  width: 500px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
  overflow-y: auto;
}

.PleaseCallMe_modalBodyMobile__3Spt7 {
  width: 80%;
}

.PleaseCallMe_validBody__3pQM5 {
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
}

.PleaseCallMe_validBodyMobile__CeO74 {
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
}

/* .iconImage {
  padding-left: 7px;
} */

.PleaseCallMe_iconMobileImage__36GQM {
  padding-left: 0;
  padding-right: 20px;
}

/* .iconContainer {
  padding-left: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #4d618d;
  font-size: 16px;
  padding-top: 10px;
  outline: none;
  background-color: #001b54;
}

.iconContainer:hover {
  cursor: pointer;
  background-color: #102b64 !important;
} */

.PleaseCallMe_iconText__1te_Z {
  display: inline-block;
  font-weight: 300;
  font-size: 12px;
  margin: 0;
}

.PleaseCallMe_iconMobileText__1UeGq {
  font-size: 16px;
  padding-left: 8px;
  vertical-align: top;
  padding-top: 5px;
}

.PleaseCallMe_form__s-uHs {
  width: 80%;
  margin: 0 auto;
}

.PleaseCallMe_submit__3cywe {
  color: white;
}

.PleaseCallMe_okay__2LPmz {
  width: 50%;
  min-width: 200px;
}

.PleaseCallMe_cancel__IiKQC {
  border: 1px solid #001b54 !important;
  color: #001b54 !important;
  min-height: 47px;
}

.PleaseCallMe_buttonContainer__3dX2M {
  margin-top: 12px;
  display: inline;
}

.PleaseCallMe_buttonContainer__3dX2M > div {
  width: 49% !important;
  display: inline-block;
}

.PleaseCallMe_buttonContainer__3dX2M > div:first-child {
  margin-right: 1%;
}

.PleaseCallMe_buttonContainer__3dX2M > div:last-child {
  margin-left: 1%;
}

.PleaseCallMe_helperText__raHso {
  text-align: center;
}

.PleaseCallMe_helperTextMobile__1mBUP {
}

.PleaseCallMe_helperText__raHso > p {
  margin: 5px auto;
  max-width: 80%;
  word-wrap: break-spaces;
}

.PleaseCallMe_helperTextMobile__1mBUP > p {
  max-width: 80%;
}

.PleaseCallMe_boldText__3AEHy {
  margin-top: 15px !important;
  font-weight: bold;
}

.PleaseCallMe_topLogo__3s36q {
  height: 64px;
  width: 64px;
  margin: 25px auto 0;
}

.PleaseCallMe_dropdown__35MGk {
  border-color: #021640;
  min-height: 47px;
  margin-top: 7px !important;
}

.PleaseCallMe_dropdownlistError__3BR4Y {
  color: red;
  font-size: 14px;
}

/* Please call Me Configure */
.PleaseCallMe_content__3WNsu {
  width: calc(90% + 60px);
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.PleaseCallMe_previewContent__oF56n{
  width: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.PleaseCallMe_preview__2wbor {
  height: 350px;
  /* left: 200px; */
}
.PleaseCallMe_configContent__1L3nT {
  width: 516px
}
.PleaseCallMe_header__1KEog {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #001B54;
}

.PleaseCallMe_addOptionsText__2UBX9 {
  position: relative;
  color: #555555;
  margin-left: 10px;
}

.PleaseCallMe_addOption__1RxPd {
  /* background-color: red; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

.PleaseCallMe_inputContainer__1p32I {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center
}

.PleaseCallMe_closeIcon__Hw82Y {
  color: #001B54;
  margin-top: 15px;
  margin-left: 15px;
}

.PleaseCallMe_addIcon__2Hk7M {
  color: #001B54
}

.PleaseCallMe_inputContainer__1p32I > div:first-child {
  width: 425px;
  margin: 0;
  min-height: 35px;
}

.PleaseCallMe_inputContainer__1p32I > div:first-child > input {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  color: #001B54
}

.PleaseCallMe_fieldContent__vdpQX {
  margin-top: 12px;
}

.PleaseCallMe_configSubmit__3KTrT {
  width: 425px;
  height: 35px;
  background: #001B54;
}

.PleaseCallMe_configSubmit__3KTrT:hover {
  background: #001B54;
}

.PleaseCallMe_configComplete__leArG {
  color: #0d7100;
  margin-top: 15px;
}

.PleaseCallMe_label__1E4gp {
  font-size: 14px;
  color: #021640;
  margin-bottom: 5px;
  margin-top: 8px;
  text-align: left;
}

.PleaseCallMe_pleaseCallInput__DVmlr {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #707071;
  box-sizing: border-box;
  background: #fff;
  color: #707071;
  height: 47px;
  margin: 0 auto;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  padding: 6px;
}
.login-body {
  background-image: url(/static/media/web-bg.b08f82e4.png);
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.login-container {
  overflow: hidden;
  width: 760px;
  height: auto;
  text-wrap: normal;
  position: relative;
  top: 25%;
  margin: auto;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 60px;
  box-shadow: 0 10px 25px 10px #00000030;
  background: white;
  z-index: 2;
}

.text-input {
  outline: none;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid #0070b9;
  color: #001b54;
  height: 40px;
  margin: -30px auto 0;
  background: transparent;
  width: 100%;
  position: absolute;
  bottom: 0;
  box-shadow: none;
  padding: 0 15px;
}

.text-input:valid {
  margin: 0 auto;
}

.text-input:focus {
  margin: 0 auto;
}

.input-container {
  position: relative;
  width: 100%;
  min-height: 78px;
  /* margin-left:0px;
  margin-right:0px; */
  text-align: left;
  margin: auto;
  overflow: hidden;
}

.combo-parent {
  overflow: visible;
}

.combo-parent p {
  margin: 0 0 16px;
  color: red;
  font-size: 14px;
  height: auto;
}

.combo-parent > div {
  overflow: visible;
  margin-top: 31px;
}

.dropdown-list {
  border-color: #707071 !important;
  height: 47px;
  overflow: visible;
  outline: none;
  box-shadow: none !important;
}

.dropdown-list-error {
  border-color: red !important;
}

.negative-margin {
  margin-top: -23px;
}

.dropdown-list * {
  box-shadow: none;
  outline: none;
  color: #707071;
  font-weight: 400;
  font-size: 14px;
  padding-left: 6px;
}

.dropdown-list span {
  padding-left: 0;
}

.dropdown-list:before {
  content: 'Region *';
  color: #021640;
  position: absolute;
  bottom: 100%;
  width: 100%;
  font-size: 14px;
  height: 31px;
  line-height: 31px;
  z-index: 99;
}

.forgot-link {
  color: #021640;
  font-size: 14px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.login-box-logo {
  height: 80px;
}

.body-corner-logo-top {
  height: 150px;
  position: fixed;
  top: 10%;
  left: 10%;
}

.get-more-image-bottom {
  height: 50px;
  position: fixed;
  top: 90%;
  left: 80%;
}

.login-box-image-container {
  margin: 0 auto;
  width: 80px;
  text-align: center;
}

.login-validation-message {
  text-align: center;
  margin-top: 15px;
  font-size: 13px;
  color: red;
}

.login-validation-message-hidden {
  display: none;
}

.login-container input[readonly] {
  cursor: text;
  background-color: #fff;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

@media (max-width: 1023px) {
  .login-body {
    background-image: url(/static/media/mobile-bg.3f3833e9.png);
    background-size: 100% 100%;
    overflow: auto;
  }

  .login-container {
    width: 80%;
    position: relative;
    margin: auto;
    margin-bottom: 60px !important;
    left: 0;
    padding: 20px;
    top: 25%;
  }

  .body-corner-logo-top {
    top: 5%;
    left: 5%;
    height: 100px;
  }

  .get-more-image-bottom {
    height: 40px;
    position: fixed;
    top: 90%;
    left: calc(90% - 120px);
  }
}

.login_loginContainer__27KHf {
  overflow: hidden;
  width: 450px;
  height: auto;
  max-width: 450px;
  text-wrap: normal;
  position: relative;
  top: 25%;
  margin: auto;
  margin-bottom: 60px !important;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 20px 40px;
  box-shadow: 0 10px 25px 10px #00000030;
  background: white;
  z-index: 2;
}

.login_links__1Izyr {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.login_register__1oTm6 {
  text-align: center;
}

.login_link__GxOd2 {
  font-size: 14px;
  cursor: pointer;
}

.login_link__GxOd2:first-child {
  margin-bottom: 25px;
}

.login_need-help__3ob7w {
  color: #021640;
  font-size: medium;
  font-size: initial;
}

@media (max-width: 1023px) {
  .login_loginContainer__27KHf {
    width: 80%;
    position: relative;
    margin: auto;
    left: 0;
    padding: 20px;
    top: 25%;
  }
}

.SelfRegistration_title__1YEOr {
  text-align: center;
  margin: 10px;
  color: #021640;
  cursor: pointer;
}

.SelfRegistration_text__17YaM {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #021640;
}

.SelfRegistration_heading__2162B {
  text-align: center;
  font-size: 20px;
  color: #021640;
}

.SelfRegistration_loginBody__U-moR {
  background-image: url(/static/media/web-bg.b08f82e4.png);
  position: relative;
  color: #021640;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.SelfRegistration_status__2sTM0 {
  text-align: center;
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.SelfRegistration_image__2dWna {
  margin: 0 auto;
  width: 80px;
  text-align: center;
}

.SelfRegistration_logo__3_SX8 {
  height: 80px;
}

.SelfRegistration_spinner__2cm_n {
  position: relative !important;
  margin: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
}

.SelfRegistration_goBack__3oXz1 {
  color: #021640;
  font-size: 14px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
}

.SelfRegistration_spinnerContainer__3sy_s {
  margin: 0;
  position: relative;
}

.SelfRegistration_otpResend__Xe_ca {
  text-align: center;
  color: green;
}

.SelfRegistration_label__1xamO {
  margin-left: 32%;
  color: #021640;
}

.SelfRegistration_registerContainer__3_UEX {
  overflow: hidden;
  max-width: 450px;
  height: auto;
  text-wrap: normal;
  position: relative;
  top: 10%;
  margin: auto;
  margin-bottom: 60px !important;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 20px 40px;
  background: white;
  z-index: 2;
}

.SelfRegistration_registerContainer__3_UEX > p {
  color: #021640;
}

.SelfRegistration_OTPInfo__1ED8q {
  font-size: 12px;
}

@media (max-width: 1023px) {
  .SelfRegistration_registerContainer__3_UEX {
    width: 80%;
    position: relative;
    margin: auto;
    left: 0;
    padding: 20px;
  }

  .SelfRegistration_userDetailsContainer__3-JYM {
    width: 80%;
    height: auto;
    position: relative;
    margin: auto;
    left: 0;
    top: 15%;
    padding: 20px;
  }
}

.registrationFields_otpLabel__XW86f {
  margin: 0 auto;
  text-align: center;
}

.registrationFields_otpErrorLabel__1VDTi {
  margin: 0 auto;
  text-align: center;
  margin-right: 61px;
}

.registrationFields_inputStyle__3K8TI {
  width: 1em;
  text-align: center;
  border: 1px solid grey;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.Timer_timer__3X52q * {
  color: #021640;
}

.Timer_timer__3X52q {
  text-align: center;
  margin-top: 15px;
  font-size: 19px;
  font-weight: bold;
}

.Timer_message__1V1LR {
  font-weight: normal;
  font-size: 14px;
}

.Timer_message__1V1LR p {
  margin: 0;
  color: #021640;
}

.PasswordStrength_passwordStrengthMeterLabel__10kJZ {
  font-size: 14px;
  display: block;
  text-align: right;
  position: relative;
  bottom: 11px;
}

.PasswordStrength_passwordStrengthMeter__2EHA2 {
  text-align: left;
  height: 0;
}

.PasswordStrength_Strength__3XE1o {
  font-weight: normal;
  font-size: 14px;
}

.PasswordStrength_Weak__VbGA5 {
  color: red;
  text-transform: uppercase;
}

.PasswordStrength_Strong__1TdM4 {
  color: green;
  text-transform: uppercase;
}

.PasswordStrength_Fair__2Ik4o {
  color: blue;
  text-transform: uppercase;
}

.PasswordStrength_Good__3WR_f {
  color: grey;
  text-transform: uppercase;
}

.ResetUserPassword_title__1y6ee {
  text-align: center;
  margin: 10px;
  color: #021640;
}

.ResetUserPassword_resendOtp__mC_fh {
  cursor: pointer;
}

.ResetUserPassword_text__j0TaE {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #021640;
}

.ResetUserPassword_heading__3EVHx {
  text-align: center;
  font-size: 20px;
  color: #021640;
}

.ResetUserPassword_helpMessage__1W7py {
  text-align: center;
}

.ResetUserPassword_loginBodyUserDetails__1btO3 {
  background-image: url(/static/media/web-bg.b08f82e4.png);
  background-size: 100% 100%;
  width: 100vw;
  height: 152vh;
  position: relative;
  color: #021640;
}

.ResetUserPassword_loginBody__1VENl {
  background-image: url(/static/media/web-bg.b08f82e4.png);
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
  color: #021640;
}

.ResetUserPassword_image__1dP41 {
  margin: 0 auto;
  width: 80px;
  text-align: center;
}

.ResetUserPassword_logo__g0cou {
  height: 80px;
}

.ResetUserPassword_label__2WwYk {
  margin-left: 32%;
  color: #021640;
}

.ResetUserPassword_registerContainer__2bmJO {
  overflow: hidden;
  max-width: 450px;
  height: auto;
  text-wrap: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: auto;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 60px;
  background: white;
  z-index: 2;
}

.ResetUserPassword_registerContainer__2bmJO > p {
  color: #021640;
}

.ResetUserPassword_userDetailsContainer__zYos_ {
  overflow: hidden;
  max-width: 450px;
  text-wrap: normal;
  position: relative;
  top: 10%;
  margin: auto;
  text-align: left;
  border: 1px solid #707071;
  border-radius: 15px;
  padding: 60px;
  background: white;
  z-index: 2;
  color: #021640;
}

@media (max-width: 1023px) {
  .ResetUserPassword_registerContainer__2bmJO {
    width: 80%;
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
  }

  .ResetUserPassword_userDetailsContainer__zYos_ {
    width: 80%;
    height: auto;
    position: relative;
    margin: auto;
    left: 0;
    top: 15%;
    padding: 20px;
  }
}

@media (max-width: 640px) {
  .ResetUserPassword_image__1dP41 {
    display: none;
  }
}

.NotificationBar_toastify___I2cp {
  min-height: 33px;
  width: 100%;
  margin-bottom: 3px;
  position: static;
  right: 0;
}

.NotificationBar_toastifyBody__1q8Y7 {
  font-size: 11px;
}

.NotificationBar_info__2P3hK {
  background: #0070b9;
}

.NotificationBar_warning__2GuOa {
  background: #d64e4e;
}

.NotificationBar_success__3Q8oL {
  background: #247d4d;
}

@media only screen and (max-width: 1023px) {
  .NotificationBar_toastify___I2cp:first-child {
    top: 55px;
  }

  .NotificationBar_toastify___I2cp {
    top: 55px;
  }

  .NotificationBar_toastOverride__1HVyn {
    z-index: 1 !important;
  }

  .NotificationBar_Toastify__toast-container--top-right__1S7lM {
    top: 55px !important;
  }

  .NotificationBar_Toastify__27mzX div {
    top: 28px;
  }
}

@media only screen and (max-width: 480px) {
  .NotificationBar_toastify___I2cp {
    margin-bottom: 0;
  }

  .NotificationBar_toastify___I2cp:first-child {
    top: 55px;
  }

  .NotificationBar_toastify___I2cp {
    top: 55px;
  }

  .NotificationBar_toastOverride__1HVyn {
    z-index: 1 !important;
  }

  .NotificationBar_Toastify__toast-container--top-right__1S7lM {
    top: 55px !important;
  }

  .NotificationBar_Toastify__27mzX div {
    top: 28px;
  }
}

