.otpLabel {
  margin: 0 auto;
  text-align: center;
}

.otpErrorLabel {
  margin: 0 auto;
  text-align: center;
  margin-right: 61px;
}

.inputStyle {
  width: 1em;
  text-align: center;
  border: 1px solid grey;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}
