.listStyle {
  list-style: none;
  display: block;
  padding-left: 0;
  margin-top: 40px;
}

.listStyleFirstChild {
  cursor: pointer;
  font-size: 16px;
  border-bottom: 2px solid #bcbcbc;
  border-top: 2px solid #bcbcbc;
  padding: 15px;
  padding-left: 35px;
  text-transform: uppercase;
}

.listStyleChildren {
  cursor: pointer;
  font-size: 16px;
  border-bottom: 2px solid #bcbcbc;
  box-sizing: border-box;
  padding: 15px;
  padding-left: 35px;
  text-transform: uppercase;
}

.listStyleChildren:hover {
  cursor: pointer;
  background-color: #0070b9;
  color: #fff;
}

.listStyleFirstChild:hover {
  cursor: pointer;
  background-color: #0070b9;
  color: #fff;
}

.closeIconSize {
  width: 30px;
  margin-top: 5px;
}

.mobileSearch {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  z-index: 10;
  overflow: auto;
}

.mobileSearchHeading {
  color: #021640;
  text-align: center;
  font-family: 'Lato';
  font-weight: 700;
  margin-bottom: 25px;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .mobileSearchHeading {
    font-size: 18px;
  }
}
