.timer * {
  color: #021640;
}

.timer {
  text-align: center;
  margin-top: 15px;
  font-size: 19px;
  font-weight: bold;
}

.message {
  font-weight: normal;
  font-size: 14px;
}

.message p {
  margin: 0;
  color: #021640;
}
